import { Linking, View } from "react-native";
import { spacing_l } from "../../StyleHelpers";
import { VERSION, globalAny } from "../../utils/Utils";
import LabelContainer from "../../components/SettingsComponent/labelContainer";
import styled from "styled-components/native";
import SettingsContainer from "../../components/Layout/SettingsContainer";
import External from "../../assets/Icons/External.svg";

const StyledContainer = styled.View`
	padding: 0px 0px ${spacing_l} 0px;
	width: 100%;
`;

const SettingsHelpAndAbout = () => {
	return (
		<View style={{ paddingTop: 30, marginBottom: "5%" }}>
			<SettingsContainer>
				{/* @ts-ignore */}
				<StyledContainer>
					<LabelContainer header={globalAny.language.ridevue_version + VERSION} body={"Sony Pictures"}></LabelContainer>
				</StyledContainer>
				{/* @ts-ignore */}
				<StyledContainer>
					<LabelContainer
						header={globalAny.language.for_faq}
						subHeader={""}
						onPressSubHeader={() => Linking.openURL("")}
						isIcon={true}
						disableIcon={true}
						imageSource={External}
						onClickableBody={false}
					></LabelContainer>
				</StyledContainer>
				{/* @ts-ignore */}
				<StyledContainer>
					<LabelContainer
						header={globalAny.language.problem_exp}
						subHeader={""}
						onPressSubHeader={() => Linking.openURL("")}
						isIcon={true}
						disableIcon={true}
						imageSource={External}
						onClickableBody={false}
					></LabelContainer>
				</StyledContainer>
				{/* @ts-ignore */}
				<StyledContainer>
					<LabelContainer
						header={globalAny.language.sony_acknowledge}
						body={globalAny.language.help_and_about_body}
						footer={"http://www.geoffdifford.com/computers/wsa.html"}
						onPressFooter={() => Linking.openURL("http://www.geoffdifford.com/computers/wsa.html")}
						onClickableFooter={false}
						isFooterIcon={true}
					></LabelContainer>
				</StyledContainer>
			</SettingsContainer>
		</View>
	);
};

export default SettingsHelpAndAbout;
