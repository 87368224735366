import { FlatList, View, TouchableOpacity } from "react-native";
import { useEffect, useState } from "react";
import { palladium, spacing_s } from "../../StyleHelpers";
import { AsyncStorageKeys, Pages } from "../../Types";
import { accountCredits, accountCreditsExpiration, accountSubscription } from "../../services/accountService";
import {
	ProfileNameSelectScreenNavigationProp,
	RecoverPasswordScreenNavigationProp,
	RedeemVoucherScreenNavigationProp,
	routeDataManagementSettings,
	routeProfileNameSelect,
	routeRecoverPassword,
	routeRedeemVoucher,
	SettingsDataManagementNavigationProp,
} from "../../Routes";
import { formatDate, getTranslatedDeviceName, globalAny, historyFormat } from "../../utils/Utils";
import { useNavigation } from "@react-navigation/native";
import * as CacheStorageUtils from "../../utils/CacheStorageUtils";
import LabelContainer from "../../components/SettingsComponent/labelContainer";
import styled from "styled-components/native";
import GroupContainer from "../../components/SettingsComponent/groupContainer";
import SettingsContainer from "../../components/Layout/SettingsContainer";
import PlaylistTitle from "../../components/PlaylistTitle";
import { purchaseHistory } from "../../services/paymentService";
import { TransactionHistory } from "../../models/TransactionHistory";
import { PurchaseHistory } from "../../models/PurchaseHistory";
import TransactionHistoryLoader from "../../components/Loaders/TransactionHistoryLoader";
import H5 from "../../components/Typography/H5";
import { removeCacheItem } from "../../utils/CacheStorageUtils";
import usePaymentTransactionStore from "../../store/usePaymentTransaction.store";

const StyledContainer = styled.View`
	padding: 0px 0px ${spacing_s} 0px;
`;

const StyledTransaction = styled.View`
	padding: 0px 0px ${spacing_s} 0px;
	flex-direction: row;
`;

const StyledNoTransaction = styled.View`
	margin-bottom: 16;
`;

const SettingAccount = () => {
	const [accountEmail, setAccountEmail] = useState("");
	const [deviceName, setDeviceName] = useState("");
	const [credits, setCredits] = useState("");
	const [creditsExpirationText, setCreditsExpirationText] = useState("");
	const [subscriptionText, setSubscriptionText] = useState("");
	const [transactionHistory, setTransactionHistory] = useState<TransactionHistory[]>([]);
	const [history, setHistory] = useState<PurchaseHistory[]>([]);
	const [transactionResponse, setTransactionResponse] = useState();
	const navigationRecoverPasswordScreen = useNavigation<RecoverPasswordScreenNavigationProp>();
	const navigationRedeemVoucherScreen = useNavigation<RedeemVoucherScreenNavigationProp>();
	const navigationSettingsDataManagement = useNavigation<SettingsDataManagementNavigationProp>();
	const navigationProfileNameSelectScreen = useNavigation<ProfileNameSelectScreenNavigationProp>();
	const setTransactionValue = usePaymentTransactionStore((state: any) => state.setTransactionValue);

	useEffect(() => {
		let unmounted = false;
		if (!unmounted) {
			const userEmail = localStorage.getItem(AsyncStorageKeys.email);
			const userDeviceName = localStorage.getItem(AsyncStorageKeys.profileName);

			if (userEmail && userDeviceName) {
				setAccountEmail(userEmail);
				setDeviceName(userDeviceName);
			}

			(async () => {
				await Init();
			})();
		}

		return () => {
			unmounted = true;
			setAccountEmail("");
			setDeviceName("");
			setCreditsExpirationText("");
			setSubscriptionText("");
		};
	}, []);

	const Init = async () => {
		let userCredits: any;
		let userCreditsExpiration: any;
		let userSubscription: any;
		let userTransactionHistory: any;

		const promiseResult = await Promise.allSettled([accountCredits(), accountCreditsExpiration(), accountSubscription(), purchaseHistory()]);
		// @ts-ignore
		const [userCreditsData, userCreditsExpirationData, userSubscriptionData, purchaseHistoryData] = promiseResult.map((i) => i.value);

		CacheStorageUtils.setCacheItem(
			AsyncStorageKeys.accountDetails,
			[userCreditsData, userCreditsExpirationData, userSubscriptionData, purchaseHistoryData],
			true
		);

		userCredits = userCreditsData;
		userCreditsExpiration = userCreditsExpirationData;
		userSubscription = userSubscriptionData;
		userTransactionHistory = purchaseHistoryData;

		try {
			if (userCredits) {
				setCredits(`${userCredits.creditsAvailable} ${globalAny.language.credit_balance}`);
			}

			if (userCreditsExpiration) {
				let sortDate = userCreditsExpiration?.creditDates
					?.map((obj: any) => {
						return { ...obj, date: new Date(obj?.date) };
					})
					.sort((a: any, b: any) => a?.date - b?.date);

				if (sortDate[0]?.date) {
					setCreditsExpirationText(globalAny.language.expires_on + " " + formatDate(new Date(sortDate[0]?.date)));
				} else {
					setCreditsExpirationText(globalAny.language.redeem_voucher_credit);
				}
			}

			if (userSubscription) {
				let sortDate = userSubscription?.activeSubscriptions
					?.map((obj: any) => {
						return { ...obj, date: new Date(obj?.endDate) };
					})
					.sort((a: any, b: any) => b?.endDate - a?.endDate);

				if (sortDate[0]?.endDate) {
					setSubscriptionText(globalAny.language.expires_on + " " + formatDate(new Date(sortDate[0]?.endDate)));
				} else {
					setSubscriptionText(globalAny.language.not_subscribed);
				}
			}

			setTransactionResponse(userTransactionHistory?.responseCode);

			if (userTransactionHistory) {
				setHistory(userTransactionHistory?.purchases);
				setTransactionHistory(historyFormat(userTransactionHistory?.purchases));
				setTransactionValue(historyFormat(userTransactionHistory?.purchases));
			}
		} catch {
			removeCacheItem(AsyncStorageKeys.accountDetails);
		}
	};

	const onResetPassword = () => {
		navigationRecoverPasswordScreen.navigate(routeRecoverPassword, { settings: Pages.reset });
	};

	const onEditDeviceName = () => {
		// @ts-ignores
		navigationProfileNameSelectScreen.navigate(routeProfileNameSelect, { location: Pages.settings });
	};

	const onRedeemVoucher = () => {
		navigationRedeemVoucherScreen.navigate(routeRedeemVoucher, {});
	};

	const onTransactionHistory = () => {
		if (history?.length !== 0) {
			navigationSettingsDataManagement.navigate(routeDataManagementSettings, { location: Pages.transactions });
		}
	};
	const renderItem = ({ item }: any) => {
		return (
			<View style={{ paddingBottom: spacing_s }}>
				<LabelContainer addMarginChild={true} header={item.title} body={item.details} />
			</View>
		);
	};

	return (
		<View style={{ paddingTop: 30 }}>
			<SettingsContainer>
				{/* @ts-ignores */}
				<GroupContainer
					header={globalAny.language.my_account}
					body={accountEmail}
					buttonText={globalAny.language.change_password}
					onPress={onResetPassword}
				/>

				{/* @ts-ignores  */}
				<GroupContainer
					header={globalAny.language.my_subscription}
					body={subscriptionText}
					buttonText={"Renew Subscription"}
					hideButton={true}
				/>

				{/* @ts-ignores  */}
				<GroupContainer
					header={globalAny.language.device_name}
					body={getTranslatedDeviceName(deviceName)}
					buttonText={globalAny.language.edit}
					onPress={onEditDeviceName}
					hideButton={localStorage.getItem(AsyncStorageKeys.isInCarScreen) === "true"}
				/>
			</SettingsContainer>

			<SettingsContainer>
				{/* @ts-ignores */}
				<GroupContainer
					header={credits}
					body={creditsExpirationText}
					buttonText={globalAny.language.redeem_voucher}
					onPress={onRedeemVoucher}
				/>
			</SettingsContainer>

			<SettingsContainer>
				{/* @ts-ignores */}
				<StyledContainer>
					{/* @ts-ignores */}
					<TouchableOpacity onPress={onTransactionHistory}>
						{/* @ts-ignores */}
						<StyledTransaction>
							<PlaylistTitle onPress={onTransactionHistory} title={globalAny.language.transaction_history} noSpacing={true} />
						</StyledTransaction>
					</TouchableOpacity>

					{transactionHistory && (
						<FlatList
							keyExtractor={(item) => item.title}
							data={transactionHistory.slice(0, 3)}
							extraData={transactionHistory.slice(0, 3)}
							renderItem={renderItem}
							showsVerticalScrollIndicator={false}
							showsHorizontalScrollIndicator={false}
						/>
					)}

					{transactionResponse !== 10000 && <TransactionHistoryLoader />}

					{history?.length === 0 && transactionResponse === 10000 && (
						//@ts-ignores
						<StyledNoTransaction style={{ marginBottom: 16 }}>
							<H5 fontColor={palladium}>{globalAny.language.no_transaction_history}</H5>
						</StyledNoTransaction>
					)}
				</StyledContainer>
			</SettingsContainer>
		</View>
	);
};

export default SettingAccount;
