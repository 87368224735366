import { View } from "react-native";
import { useEffect, useState } from "react";
import { globalAny } from "../../utils/Utils";
import { legalTitleFontSize, spacing_m, white_smoke } from "../../StyleHelpers";
import ButtonToggle from "../ButtonToggle";
import Text from "../Text/Text";
import FlexRow from "../Layout/FlexRow";
import TouchableButton from "../TouchableButton";
import ComponentThemeType from "../../models/ComponentTheme";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import styled from "rn-css";
import useAcceptDataStore from "../../store/useAcceptData.store";
import WrapperCreateAccount from "../Layout/WrapperCreateAccount";
import CreateAccountButton from "../CreateAccountButton";
import PrivacyPolicyButton from "../PrivacyPolicyButton";
import * as CacheStorageUtils from "../../utils/CacheStorageUtils";
import { AsyncStorageKeys, Type } from "../../Types";

const StyledContainer = styled.View`
	z-index: -1;
	height: 100%;
`;

const StyledSwitchBodyStyle = styled.View`
	font-size: 18px;
	margin: 5px 0 0 133px;
	color: ${white_smoke};
	font-weight: 500;
	@media (min-width: 1536px) {
		margin-top: 5px;
	}
	@media (max-height: 500px) and (min-height: 300px) {
		margin-top: -5px;
		font-size: 17px;
	}
	@media (max-height: 620px) and (min-height: 500px) {
		font-size: 14px;
	}

	@media (max-height: 900) and (min-height: 800px) {
		font-size: 22px;
	}
	@media (max-height: 1080px) and (min-height: 920px) {
		font-size: 25px;
		margin-top: 20px;
	}
`;

const StyledLegalTitleStyle = styled.Text`
	font-size: 37px;
	text-align: left;
	@media (min-width: 640px) {
		font-size: 32px;
	}
	@media (min-width: 1536px) {
		font-size: 37px;
	}
	@media (max-height: 610px) and (min-height: 500px) {
		font-size: 30px;
	}
`;

const StyledButtonContainer = styled.View<any>`
	margin: 0 14.1% 0 14.1%;
	width: 71.8%;
	position: absolute;
	bottom: 15%;
	@media (min-width: 1600px) and (max-width: 1600px) {
		position: relative;
		flex-direction: row;
		bottom: 2%;
		width: ${(props) => (props.showPrivacyLink ? "55%" : "")};
		margin-left: ${(props) => (props.showPrivacyLink ? "30%" : "")};
	}
`;

const StyledCreateButton = styled.View`
	margin-left: 133px;
	margin-top: 32px;
	font-size: 18px;
	@media (min-width: 640px) {
		margin-top: 5px;
	}
	@media (min-width: 1536px) {
		margin-top: 32px;
	}
`;

const StyledDataContainer = styled.View<any>`
	opacity: ${(props) => (props.disabled ? 0.5 : 1)};
	@media (max-height: 610px) {
		margin-top: -70px;
	}
	@media (max-height: 610px) and (min-height: 500px) {
		margin-top: -50px;
	}
`;

interface Props {
	onPressPrimaryButton?: () => void;
	onPressSecondaryButton?: () => void;
	onPressSonyPictures?: () => void;
	onPressSonyCompany?: () => void;
	onPressPrivacy?: () => void;
	showPrivacyLink?: boolean;
	type: any;
	title?: string;
	description?: string;
	sonyPictures?: any;
	sonyCompany?: any;
}

const AcceptDataComponent = (props: Props) => {
	const { acceptData, setAcceptData } = useAcceptDataStore();
	const [optionDescription, setOptionDescription] = useState("");
	const createAccountButtonData = [
		{
			title: globalAny.language.sony_pictures,
			onPress: props.onPressSonyPictures,
			disabled: !props.sonyPictures,
		},
		{ title: globalAny.language.sony_group, onPress: props.onPressSonyCompany, disabled: !props.sonyCompany },
	];

	const touchableButtonData = [
		{
			title: globalAny.language.back,
			type: ComponentTypeEnum.Secondary,
			disabled: false,
			addMarginRight: true,
			onPress: props.onPressSecondaryButton,
		},
		{
			title: globalAny.language.next,
			type: "",
			addMarginLeft: true,
			disabled: false,
			onPress: props.onPressPrimaryButton,
		},
	];

	const asyncAcceptData = async () => {
		if (props.type === Type.Settings) {
			const acceptData = await CacheStorageUtils.getCacheItem(AsyncStorageKeys.acceptOtherDataSettings, true);
			const [sections] = acceptData?.sections ?? [];
			const [options] = sections?.options ?? [];
			const optionDesc = options?.optionsWordings?.find((val: any) => val?.key === "option-description");
			return setOptionDescription(optionDesc?.textValue);
		}

		if (props.type === Type.CreateAccount) {
			const acceptData = await CacheStorageUtils.getCacheItem(AsyncStorageKeys.acceptOtherDataSignUp, true);
			const [sections] = acceptData?.sections ?? [];
			const [options] = sections?.options ?? [];
			const optionDesc = options?.optionsWordings?.find((val: any) => val?.key === "option-description");
			return setOptionDescription(optionDesc?.textValue);
		}
	};

	useEffect(() => {
		// Retrieve saved state from Zustand store when component mounts
		const savedAcceptData = acceptData;

		if (savedAcceptData) {
			setAcceptData(savedAcceptData);
		}
	}, []);

	useEffect(() => {
		// Save state to Zustand store whenever it changes
		setAcceptData(acceptData);
	}, [acceptData]);

	useEffect(() => {
		// Get acceptData in Local Storage
		asyncAcceptData();
	}, []);

	const renderCreateAccountButton = ({ title, onPress, disabled }: any) => (
		<CreateAccountButton key={`create-account-button-${title}`} title={title} onPress={onPress} disabled={disabled} />
	);

	const renderTouchableButtonData = ({ title, type, addMarginRight, addMarginLeft, disabled, onPress }: any) => (
		<TouchableButton
			key={`create-account-touchable-${title}`}
			addMarginLeft={addMarginLeft}
			addMarginRight={addMarginRight}
			componentTheme={ComponentThemeType.VinFast}
			type={type}
			onPress={onPress}
			disabled={disabled}
		>
			{title}
		</TouchableButton>
	);

	return (
		// @ts-ignore
		<StyledContainer>
			<WrapperCreateAccount>
				<StyledDataContainer disabled={!props.sonyCompany || !props.sonyPictures}>
					<FlexRow>
						<ButtonToggle
							defaultValue={acceptData}
							onPress={(state) => setAcceptData(state)}
							toggleDisabled={!props.sonyCompany || !props.sonyPictures}
						/>
						<View style={{ marginLeft: spacing_m }}>
							<Text fontSize={legalTitleFontSize}>
								{/* @ts-ignore */}
								<StyledLegalTitleStyle>{props.title ?? globalAny.language.accept_data}</StyledLegalTitleStyle>
							</Text>
						</View>
					</FlexRow>
					{/* @ts-ignore   */}
					<StyledSwitchBodyStyle>{props.description ?? optionDescription}</StyledSwitchBodyStyle>
				</StyledDataContainer>
				{/* @ts-ignore */}

				<StyledCreateButton>
					{createAccountButtonData.map((item) => {
						return renderCreateAccountButton(item);
					})}
				</StyledCreateButton>
				{/* @ts-ignore   */}
			</WrapperCreateAccount>
			{/* @ts-ignore   */}
			<StyledButtonContainer showPrivacyLink={props.showPrivacyLink}>
				<FlexRow>
					{touchableButtonData.map((item) => {
						return renderTouchableButtonData(item);
					})}
				</FlexRow>
				{props.showPrivacyLink && <PrivacyPolicyButton onPress={props.onPressPrivacy} />}
			</StyledButtonContainer>
		</StyledContainer>
	);
};

export default AcceptDataComponent;
