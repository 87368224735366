import { globalAny } from "../../utils/Utils";
import { legalBodyFontSize, legalTitleFontSize } from "../../StyleHelpers";
import FlexRow from "../Layout/FlexRow";
import Text from "../Text/Text";
import styled from "rn-css";
import CreateAccountButton from "../CreateAccountButton";
import ComponentThemeType from "../../models/ComponentTheme";
import TouchableButton from "../TouchableButton";
import WrapperCreateAccount from "../Layout/WrapperCreateAccount";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import PrivacyPolicyButton from "../PrivacyPolicyButton";

interface Props {
	showButton?: boolean;
	bodyText?: string;
	onPressNext?: () => void;
	onPressBack?: () => void;
	// onPressPromotionalTerms?: () => void;
	onPressTermsOfUse?: () => void;
	onPressTermsOfService?: () => void;
	onPressPrivacy?: () => void;
}

const StyledContainer = styled.View`
	z-index: -1;
	height: 100%;
`;

const StyledSwitchBodyStyle = styled.Text`
	margin: 5px 0 0 0;
	@media (max-height: 610px) {
		margin-top: -5px;
	}
`;

const StyledLegalTitleStyle = styled.Text`
	font-size: 37px;
	text-align: left;
	@media (min-width: 640px) {
		font-size: 32px;
	}
	@media (min-width: 1536px) {
		font-size: 37px;
	}
	@media (max-height: 800px) and (min-height: 500px) {
		font-size: 30px;
	}
`;

const StyledButtonContainer = styled.View`
	margin: 0 14.1% 0 14.1%;
	width: 71.8%;
	position: absolute;
	bottom: 15%;
	@media (min-width: 1600px) and (max-width: 1600px) {
		position: relative;
		flex-direction: row;
		bottom: 2%;
		margin-top: 3%;
		width: 55%;
		margin-left: 30%;
	}
`;

const StyledCreateButton = styled.View`
	margin-left: 133px;
	margin-top: 32px;
	@media (min-width: 640px) {
		margin-top: 20px;
	}
	@media (min-width: 1536px) {
		margin-top: 45px;
	}
	@media (max-height: 610px) {
		margin-top: 5px;
	}
`;

const StyledDataContainer = styled.View`
	@media (max-height: 610px) {
		margin-top: -70px;
	}
	@media (max-height: 610px) and (min-height: 500px) {
		margin-top: -50px;
	}
`;

const LegalTermsConditions = (props: Props) => {
	const createAccountButtonData = [
		// {
		// 	title: globalAny.language.promotional_terms,
		// 	onPress: props.onPressPromotionalTerms,
		// },
		{ title: globalAny.language.terms_of_use, onPress: props.onPressTermsOfUse },
		{ title: globalAny.language.terms_of_service, onPress: props.onPressTermsOfService },
	];

	const touchableButtonData = [
		{
			title: globalAny.language.back,
			type: ComponentTypeEnum.Secondary,
			disabled: false,
			addMarginRight: true,
			onPress: props.onPressBack,
		},
		{
			title: globalAny.language.next,
			type: "",
			addMarginLeft: true,
			disabled: false,
			onPress: props.onPressNext,
		},
	];

	const renderCreateAccountButton = ({ title, onPress }: any) => (
		<CreateAccountButton key={`legal-term-button${title}`} title={title} onPress={onPress} />
	);

	const renderTouchableButtonData = ({ title, type, addMarginRight, addMarginLeft, disabled, onPress }: any) => (
		<TouchableButton
			key={`legal-term-touchable-${title}`}
			addMarginLeft={addMarginLeft}
			addMarginRight={addMarginRight}
			componentTheme={ComponentThemeType.VinFast}
			type={type}
			onPress={onPress}
			disabled={disabled}
		>
			{title}
		</TouchableButton>
	);

	return (
		//@ts-ignore
		<StyledContainer>
			<WrapperCreateAccount>
				<StyledDataContainer>
					<FlexRow>
						<Text fontSize={legalTitleFontSize}>
							{/* @ts-ignore */}
							<StyledLegalTitleStyle>{globalAny.language.legal_terms}</StyledLegalTitleStyle>
						</Text>
					</FlexRow>
					{/* @ts-ignore   */}
					<StyledSwitchBodyStyle>
						<Text fontSize={legalBodyFontSize}>{props.bodyText}</Text>
					</StyledSwitchBodyStyle>
					{/* @ts-ignore */}
					<StyledCreateButton>
						{createAccountButtonData.map((item) => {
							return renderCreateAccountButton(item);
						})}
					</StyledCreateButton>
				</StyledDataContainer>
				{/* @ts-ignore */}
			</WrapperCreateAccount>
			{props.showButton && (
				//@ts-ignore
				<StyledButtonContainer>
					<FlexRow>
						{touchableButtonData.map((item) => {
							return renderTouchableButtonData(item);
						})}
					</FlexRow>
					<PrivacyPolicyButton onPress={props.onPressPrivacy} />
				</StyledButtonContainer>
			)}
		</StyledContainer>
	);
};

export default LegalTermsConditions;
