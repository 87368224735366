export type Carousel = {
	isProgressBar: boolean;
	isLandscape: boolean;
	playListCarousel: boolean;
	categoryName: string;
	title: string; // categoryName but for display
	uri: string | null;
	playlistId: string;
	customListId: string;
	isVam: boolean;
	products: any; // how can I type when it is dependent on server objects? can I rely on them not to change?
	isPortrait: boolean;
	id?: any;
	isBanner?: any;
	categoryImages?: any;
};

export type VamList = {
	title: string;
	customListId: any;
	products: Vam[];
	id?: any;
};
/**
 * Top row tabs
 */
export type Tab = {
	name: string;
	onPress: any;
};

/**
 * Product type from /content/v6/Playlist/{playlistId}/content
 */
export type Product = {
	comingSoon: boolean;
	imageUrl: string;
	packshots: any;
	productId: number;
	streamUrl: string;
	parentProductId: number;
	title: string;
	productTitle: string;
	rating: string;
	ratingReason: string;
	transactionTypes: any;
	duration: string;
	genres: any;
	contentType: number;
	numberOfEpisodes: any;
	numberOfSeasons: any;
	season: any;
	episode: any;
	seasonId: any;
	seriesId: any;
	availableEpisodes: any;
	year: string;
	vamStatus?: number;
	id?: number;
};

/**
 * Props for Landscape and VAM packshots
 */
export type PackshotProps = {
	item: Product;
	onLoadImg?: (e: any) => void;
	onPress?: () => void;
};

/**
 * A device connecting to MQTT
 */
export type Device = {
	device: string; // autogenerated unique id
	profileName: string; // user entered profileName
};

/**
 * Category text
 */
export type CategoryTextTranslation = {
	name: string;
	text: string;
};

/**
 * Dot under the featured carousel
 */
export type Dot = {
	isActive: boolean;
};

/**
 * Represents a language e.g. english, spanish, chinese, etc
 */
export type Language = {
	Strings: LanguageStrings;
};

/**
 * Strings object within a language must only have strings for both keys and values
 */
export type LanguageStrings = {
	[key: string]: string;
};

/**
 * Global Types
 */
export type GlobalType = {
	SESSIONKEY: string;
	APIKEY: string;
	CONSUMERID: string;
	profileName: string;
	language: LanguageStrings;
};

/**
 * Login Button parameters
 */
export type LoginButtonsProps = {
	leftButtonText: string;
	leftButtonFunction: any;
	leftButtonDisabled: boolean;
	rightButtonText: string;
	rightButtonFunction: any;
	rightButtonDisabled: boolean;
};

/**
 * Enumerator for categories options
 */
export enum Categories {
	home = "home",
	myLibrary = "my-library",
	studioTwo = "studio-two",
	preview = "preview",
}

/**
 * Enumerator for top navigation tab options
 */
export enum Tabs {
	home = "Home",
	screenManager = "Screen Manager",
	tripAssist = "Trip Assist",
	myLibrary = "My Library",
	settings = "Settings",
}

/**
 * Enumerator for Pages and Routes
 */
export enum Pages {
	main = "main",
	reset = "reset",
	assignScreen = "assignscreen",
	settings = "settings",
	transactions = "transactions",
	legal = "legal",
	privacy = "privacy",
	data = "data",
	controller = "controller",
	search = "search",
	library = "library",
	titleDetailsPage = "titleDetailsPage",
	homeAggregatePage = "homeaggregate",
	vamAggregatePage = "vamaggregate",
	shortClips = "shortClips",
	landing = "landing",
	preview = "preview",
	codeSignIn = "code-signin",
	page = "page",
	acceptData = "acceptData",
	otherData = "otherData",
	legalTerms = "legalTerms",
	createAccount = "createAccount",
}

export enum SubPages {
	termsOfUse = "termsOfUser",
	termsOfService = "termsOfService",
	sonyPictures = "sonyPictures",
	sonyGroup = "sonyGroup",
	googleAnalytics = "googleAnalytics",
	videoAnalytics = "videoAnalytics",
}

/**
 * Enumerator for DeviceSelectionPages
 */
export enum DeviceSelectionPages {
	DemoOptions = "DemoOptions",
	ScreenSelectionLoader = "ScreenSelectionLoader",
	ScreenSelection = "ScreenSelection",
	OtherDevices = "OtherDevices",
}

/**
 * Enumerator for Routes
 */
export enum direction {
	main = "main",
	player = "player",
	studio = "studio",
	tdp = "tdp",
	assign = "assign",
}

/**
 * Enumerator for Screen manager controller
 */
export enum ControllerEnum {
	Pause = "Pause",
	Resume = "Resume",
	Stop = "Stop",
	Restart = "Restart",
	Sync = "Sync",
	Cancel = "Cancel",
	Play = "Play",
}

/**
 * Enumerator for Progress video events
 */
export enum ProgressEvents {
	start = "Start",
	update = "Update",
	end = "End",
	complete = "Complete",
}

/**
 * Enumerator for Create account states
 */
export enum CreateAccountState {
	validateVoucher = "Validate",
	legalOne = "LegalOne",
	legalTwo = "LegalTwo",
	legalThree = "LegalThree",
	legalFour = "LegalFour",
	enterEmail = "Email",
	password1 = "Password1",
	password2 = "Password2",
	birthday = "birthday",
	termsAndConditions = "termsAndConditions",
	newCreateAccountForm = "newCreateAccountForm",
}

/**
 * Enumerator for Create account states
 */
export enum RecoverPasswordState {
	recover = "Recover",
	changePassword = "ChangePassword",
}

/**
 * Enumerator for login states
 */
export enum LoginState {
	signIn = "SignIn",
	email = "Email",
	password = "Password",
}

/**
 * Enumerator for profileName selection states
 */
export enum ProfileNameState {
	profileNameSelect = "ProfileNameSelect",
	profileNameManualSelect = "ProfileNameManualSelect",
}

/**
 * Enumerator for Screens
 */
export enum ProfileName {
	FrontScreen = "Front Screen",
	LeftScreen = "Left Rear",
	RightScreen = "Right Rear",
	OtherDevice = "Other Device",
	RearScreen = "Rear Screen",
	PassengerScreen = "Front Passenger",
}

/**
 * Enumerator for all states in the app besides login credentials and profileName selection
 */
export enum MainAppState {
	home = "",
	screenManager = "ScreenManager",
	screenManagerController = "ScreenManagerController",
	tripAssist = "TripAssist",
	myLibrary = "MyLibrary",
	settings = "Settings",
	titleDetails = "TitleDetails",
	titleMetadata = "TitleMetadata",
	player = "Player",
	trailer = "Trailer",
	vamPlayer = "VamPlayer",
	quickNav = "QuickNav",
	gridView = "GridView",
	categoryPage = "CategoryPage",
}

/**
 * Enumerator for Async Storage items
 */
export enum AsyncStorageKeys {
	mqttReceiverEnabled = "mqttReceiverEnabled",
	email = "email",
	password = "password",
	refreshToken = "refreshToken",
	sessionExpiry = "sessionExpiry",
	existingLanguageData = "existingLanguageData",
	languageJson = "languageJson",
	apiKey = "apiKey",
	session = "session",
	consumerId = "consumerId",
	profileName = "profileName",
	deviceId = "deviceId",
	birthday = "birthday",
	SelectedAudioLanguage = "selectedAudioLanguage",
	SelectedSubtitleLanguage = "selectedSubtitleLanguage",
	LanguageOverride = "LanguageOverride",
	RegionOverride = "RegionOverride",
	EnvironmentOverride = "EnvironmentOverride",
	featuredPlaylists = "featuredPlaylists",
	homePlaylists = "playlist_home",
	legals = "legals",
	acceptOtherData = "acceptOtherData",
	accountDetails = "accountDetails",
	idleExpiry = "idleExpiry",
	allowUntil = "allowUntil",
	theme = "theme",
	carActivity = "carActivity",
	floatingButton = "floatingButton",
	consumerDeviceId = "consumerDeviceId",
	trackingId = "trackingId",
	playSessionId = "playSessionId",
	watchGroup = "watchGroup",
	totalDuration = "totalDuration",
	streamProgress = "streamProgress",
	sessionExpired = "sessionExpired",
	isInCarScreen = "isInCarScreen",
	wheelPosition = "wheelPosition",
	addScreenCount = "addScreenCount",
	hwid = "hwid",
	masteruser = "masteruser",
	deviceIdentifier = "deviceIdentifier",
	hwidEncrypted = "hwidEncrypted",
	dataExchange = "dataExchange",
	shakaSettingsAudio = "shakaSettingsAudio",
	shakaSettingsSubtitle = "shakaSettingsSubtitle",
	subtitleOnAndOff = "subtitlesOnAndOff",
	acceptOtherDataSettings = "acceptOtherData-settings",
	acceptOtherDataSignUp = "acceptOtherData-signUp",
	parentProductId = "parentProductId",
	enableLogs = "enableLogs",
	enableMQTT = "enableMQTT",
	inWatchGroup = "inWatchGroup",
	customerZone = "customerzone",
	subscription = "subscription",
	isOverride = "isOverride",
	cacheAcceptOtherDataCreate = "cacheAcceptOtherDataCreate",
	cacheAcceptOtherDataSettings = "cacheAcceptOtherDataSettings",
	cacheLegalDataCreate = "cacheLegalDataCreate",
}

export enum PurchaseTransactionType {
	Purchase = 1,
	Rent = 3,
}

/**
 * Buttons on the ProfileNameSelect screen
 */
export type ProfileNameButtonProps = {
	text: string;
	iconPath?: string;
	onPress: () => void;
	visible: boolean;
};

/**
 * For Scrolling through carousels using arrow buttons
 */
type LeftOrRight = "left" | "right";
export type ArrowButtonProps = {
	direction: LeftOrRight;
	onPress: () => void;
	windowDimensions: any;
};

/**
 * Icon buttons on the home screen
 */
export type IconButtonProps = {
	icon: string;
	iconTitle: string;
	iconName?: string;
	iconPlaylist?: string;
	iconImage: string;
	iconPageId?: string;
};

export type Packshot = {
	width: number;
	height: number;
	isLandscape: boolean;
	url: string;
};

export type ApiHeaders = {
	Accept: string;
	ApiKey: string;
	AppLanguage: string;
	Session?: string;
	"Content-Type": string;
	pragma?: string;
};

export type ApiVoucherHeaders = {
	Accept: string;
	ApiKey: string;
	AppLanguage: string;
	Session: string;
	Token: string;
};

/**
 * Channel type for when a movie is playing to be displayed on the Screen Manager
 */
export type ActivePlaybackChannel = {
	parentProductId: number;
	title: string;
	mqttCommand: any;
	devices: Device[];
};

/**
 * MQTT Message type for when playback is active or inactive
 */
export type PlaybackMessage = {
	active: boolean;
	device: Device;
	parentProductId: number;
	title: string;
};

export type PlaybackChannelButton = {
	channelNumber: number;
	title: string;
	activeDevices: Device[];
	packshots: [];
	parentProductId: number;
	mqttCommand: any;
};

/**
 * Vam movies (extras or trailers)
 */
export type Vam = {
	id: number;
	imageUrl: string;
	subType: any;
	summary: string;
	title: string;
	type: string;
	productId?: number;
	contentId?: string;
	productTitle?: string;
	paywall?: boolean;
	vamStatus?: number;
};

export enum Themes {
	DARK = "DARK",
	LIGHT = "LIGHT",
}

export enum CarActivity {
	INMOTION = "IN-MOTION",
	PARKED = "PARKED",
}

export enum WheelPosition {
	LEFT = "left",
	RIGHT = "right",
}

/**
 * Enumerator for category template types
 */
export enum CategoryTemplateTypes {
	FeatureTemplate = "feature-template",
	Banner = "banner",
	HKA = "hka", //landscape
	STD = "std", // portrait
	VAM = "vam", // Extras and Studio Access
	ContinueWatching = "continue-watching",
	Download = "grid-view-downloads",
	Grid = "grid-view",
}

export enum DigitalPaymentSolution {
	ApplePay = 1,
	GooglePay,
}

export enum Type {
	//settingsType
	Settings = 0,
	//SignUp/CreateAccountType
	CreateAccount = 1,
}

export enum Toggles {
	qrCode = "ridevu-qr-code",
	autoLogin = "ridevu-auto-login",
	mqtt = "ridevu-mqtt",
	advanceSettings= "ridevu-advanced-settings",
}
