import { useNavigation } from "@react-navigation/native";
import { SettingsDataManagementNavigationProp, routeDataManagementSettings } from "../../Routes";
import SettingsContainer from "../../components/Layout/SettingsContainer";
import LabelContainer from "../../components/SettingsComponent/labelContainer";
import LegalButton from "../../components/LegalButton";
import iconProceed from "../../assets/TopNavBarIcon/icon_nav_proceed.png";
import { Linking, View } from "react-native";
import styled from "styled-components/native";
import { spacing_l } from "../../StyleHelpers";
import { AsyncStorageKeys, Pages } from "../../Types";
import YourPrivacyChoice from "../../assets/Icons/YourPrivacyChoices.png";
import { globalAny, isSkipDataManagement } from "../../utils/Utils";
import { useEffect } from "react";
import useLegalContentStore from "../../store/useLegalContent.store";

const StyledContainer = styled.View`
	padding: 0px 0px ${spacing_l} 0px;
`;

const SettingsLegal = () => {
	const skipDataManagement = isSkipDataManagement();
	const isDefaultTerritory = localStorage.getItem(AsyncStorageKeys.RegionOverride) == "US";
	const fetchLegals = useLegalContentStore((state: any) => state.fetchLegals);
	const fetchAcceptOtherData = useLegalContentStore((state: any) => state.fetchAcceptOtherData);
	const isDisabled = () => {
		if (skipDataManagement) {
			return true;
		}
		return false;
	};
	const navigationSettingsDataManagement = useNavigation<SettingsDataManagementNavigationProp>();

	const onLegal = () => {
		navigationSettingsDataManagement.navigate(routeDataManagementSettings, { location: Pages.legal });
	};

	const onPrivacy = () => {
		navigationSettingsDataManagement.navigate(routeDataManagementSettings, { location: Pages.privacy });
	};

	const onData = () => {
		if (skipDataManagement) return;
		navigationSettingsDataManagement.navigate(routeDataManagementSettings, { location: Pages.acceptData });
	};

	useEffect(() => {
		(async () => {
			await fetchLegals(AsyncStorageKeys.legals);
			await fetchAcceptOtherData(AsyncStorageKeys.acceptOtherData);
		})();
	}, []);

	return (
		<View style={{ paddingTop: 30, marginBottom: "5%" }}>
			<SettingsContainer>
				{isDefaultTerritory ? (
					/* @ts-ignore */
					<StyledContainer>
						{/* @ts-ignore */}
						<LabelContainer
							header={globalAny.language.your_privacy_choices}
							body={globalAny.language.privacy_spiel}
							onPressBody={() => Linking.openURL("https://www.sonypicturescore.com/")}
							onClickableBody={false}
							imageSource={YourPrivacyChoice}
						></LabelContainer>
					</StyledContainer>
				) : (
					<></>
				)}
				<LegalButton onPress={onLegal} title={globalAny.language.legal_terms} rightIcon={iconProceed}></LegalButton>
				<LegalButton
					onPress={onData}
					title={globalAny.language.data_management}
					rightIcon={iconProceed}
					disabled={isDisabled()}
				></LegalButton>
				<LegalButton onPress={onPrivacy} title={globalAny.language.privacy_policy} rightIcon={iconProceed}></LegalButton>
			</SettingsContainer>
		</View>
	);
};

export default SettingsLegal;
