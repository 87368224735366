import { TouchableOpacity } from "react-native";
import styled from "rn-css";
import { white_smoke } from "../../StyleHelpers";

const StyledDeviceButton = styled.View<any>`
	background-color: rgba(51, 51, 51, 1);
	height: 30vh;
	border-radius: 16px;
	border-style: ${(props) => (props.borderStyle ? "dashed" : "solid")};
	border-color: rgba(178, 178, 178, 1);
	border-width: 2px;
	text-align: center;
	justify-content: center;
	opacity: ${(props) => (props.disabled ? 0.3 : 1)};
	border: ${(props) => (props.selected ? "2px solid rgba(0, 139, 239, 1)" : "")};
	background-image: ${(props) => (props.selected ? "linear-gradient(90deg, rgba(0, 139, 239, 0.5) 0%, rgba(51, 51, 51, 0.5) 100%)" : "")};
	background-color: ${(props) => (props.selected ? "#131313" : "")};

	&:hover {
		border: ${(props) => (!props.selected ? "2px solid white" : "")};
	}
`;

const StyledText = styled.Text`
	font-size: 25px;
	color: ${white_smoke};
	font-weight: 500;
	line-height: 28px;
`;

const DeviceButton = (props: any) => {
	return (
		<TouchableOpacity
			onPress={() => {
				if (!props?.disabled) props?.setSelectedScreen(props?.value);
			}}
			style={{ width: props?.width || "100%" }}
		>
			{/* @ts-ignore */}
			<StyledDeviceButton selected={props?.selected} disabled={props?.disabled} borderStyle={props?.borderStyle}>
				<StyledText>
					{props?.text}
				</StyledText>
			</StyledDeviceButton>
		</TouchableOpacity>
	);
};

export default DeviceButton;
