import styled, { css } from "styled-components/native";
import { Button } from "react-native-paper";
import ITouchableButtonProps from "../../models/ITouchableButtonProps";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import { macaroni_and_cheese, mid_blue, navy_blue, peacock_blue, royal_blue, dark, white_smoke, black } from "../../StyleHelpers";
import { useState } from "react";

interface BtnProps {
	type?: ComponentTypeEnum;
	disabled: boolean;
	addMarginLeft?: boolean;
	addMarginRight?: boolean;
	isStreamProgress?: any;
	isHovered?: any;
}

//Note: edit flex value to allow/disable occupying whole width of button in flex component
const StyledBtnPaper = styled(Button)<BtnProps>`
	border-radius: 16px;
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: 100%;
	${(props) =>
		props.addMarginLeft &&
		css`
			margin-left: 10px;
		`}

	${(props) =>
		props.addMarginRight &&
		css`
			margin-right: 10px;
		`}
	

	${(props) => {
		switch (props.type) {
			case ComponentTypeEnum.Primary:
				return css`
					background: ${props?.isHovered ? peacock_blue : mid_blue};
				`;
			case ComponentTypeEnum.Secondary:
				return css`
					background: ${props?.isHovered ? navy_blue : royal_blue};
				`;
			case ComponentTypeEnum.Tertiary:
				return css`
					background: ${macaroni_and_cheese};
				`;
			case ComponentTypeEnum.Dark:
				return css`
					background: ${dark};
				`;
			default: //use primary as default
				return css`
					background: ${mid_blue};
				`;
		}
	}}
	${(props) =>
		props.isStreamProgress &&
		css`
			background-image: linear-gradient(to left, ${mid_blue} 0 ${100 - props.isStreamProgress}%, ${peacock_blue} 0 ${props.isStreamProgress}%);
		`}
`;

const StyledText = styled.Text<any>`
	line-height: 1.75rem;
	height: 4.5rem;
	justify-content: center;
	align-items: center;
	display: flex;
	font-size: 1.5625rem;
	white-space: normal;
	color: ${(props) => (props.type === ComponentTypeEnum.Tertiary ? black : white_smoke)};
`;

// Specs: https://callstack.github.io/react-native-paper/button.html
const TouchableButtonVinfast = (props: ITouchableButtonProps) => {
	const [isHovered, setIsHovered] = useState(false);
	return (
		// @ts-ignore
		<StyledBtnPaper
			onMouseEnter={() => setIsHovered(true)}
			onMouseLeave={() => setIsHovered(false)}
			isHovered={isHovered}
			id={props.nativeID}
			mode="contained-tonal"
			type={props.type}
			onPress={props.onPress}
			onLongPress={props.onLongPress}
			{...props}
			disabled={props.disabled}
			isStreamProgress={props.isStreamProgress}
			labelStyle={props.disabled ? { width: "100%", color: "white", opacity: 0.5 } : { width: "100%" }}
			contentStyle={{ height: "4.5rem", width: "100%" }}
		>
			{/* @ts-ignore */}
			<StyledText type={props.type}>{props.children}</StyledText>
		</StyledBtnPaper>
	);
};

export default TouchableButtonVinfast;
