import { light_black } from "../../StyleHelpers";
import TopAppbar from "../TopAppBar";
import ridevueLogo from "../../assets/Logo/iconLogo.svg";
import styled from "styled-components/native";
import backIcon from "../../assets/TopNavBarIcon/icon_nav_back.svg";
import closeIcon from "../../assets/TopNavBarIcon/icon_nav_close.svg";
import ImgProgressBar from "./ImgProgressBar";

const StyledContainer = styled.View`
	background-color: ${light_black};
	height: 100vh;
`;

const StyledOptionContainer = styled.View`
	top: 180px;
	height: 60vh;
	margin-left: 14%;
    margin-right: 14%;
`;

const ButtonContainer = styled.View`
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 48px;
`;

const ButtonWrapper = styled.View`
	overflow: hidden;
	width: 100%;
	height: 72px;
	border-radius: 16px;
	margin-bottom: 50px;
`;


const OtherDevicesLoader = () => {
	return (
		//@ts-ignore
		<StyledContainer>
			<TopAppbar
				leftIcon={closeIcon}
				leftIconWidth={92}
				leftTitleIcon={backIcon}
				screenTitle={""}
				rightLogo={ridevueLogo}
				isSingleLogo={true}
			/>
			{/* @ts-ignore */}
			<StyledOptionContainer>
				{/* @ts-ignore */}
				<ButtonContainer>
					{/* @ts-ignore */}
					<ButtonWrapper>
						<ImgProgressBar />
					</ButtonWrapper>
					{/* @ts-ignore */}
					<ButtonWrapper>
						<ImgProgressBar />
					</ButtonWrapper>
				</ButtonContainer>
			</StyledOptionContainer>
		</StyledContainer>
	);
};

export default OtherDevicesLoader;
