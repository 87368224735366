import { light_black, spacing_l, spacing_s, white } from "../../StyleHelpers";
import styled, { css } from "styled-components/native";
import { globalAny } from "../../utils/Utils";

interface Props {
	banner?: any;
}

const StyledStudioAccessBanner = styled.View<Props>`
	flex-direction: row;
	right: 0;
	${(props) =>
		props.banner &&
		css`
			background-image: url(${props.banner});
			background-position: top;
			background-repeat: no-repeat;
			background-size: cover;
		`}
	${(props) =>
		!props.banner &&
		css`
			background-color: ${white};
		`}
	height: 35vh;
`;

const StyledTextContainer = styled.View`
	padding: 8vh 0 0 ${spacing_l};
	background-color: ${light_black};
	width: 40vw;
	height: 40vh;
	z-index: 9;
`;

const StyledText = styled.Text`
	width: 20vw;
	height: 10vh;
	color: ${white};
	font-size: 1.5vw;
	margin-bottom: ${spacing_s};
	margin-left: 120px;
`;

const StyledImage = styled.View`
	height: 100%;
	background-image: linear-gradient(to left, transparent, ${light_black});
	width: 45%;
`;

const StyledGradient = styled.View`
	width: 100%;
	height: 90px;
	background-image: linear-gradient(to bottom, transparent, ${light_black});
	position: absolute;
	bottom: 0;
`;

const Banner = (props: Props) => {
	return (
		//@ts-ignore
		<StyledStudioAccessBanner banner={props.banner}>
			{/* @ts-ignore */}
			<StyledTextContainer>
				{/* @ts-ignore */}
				<StyledText>{globalAny.language.banner_text}</StyledText>
			</StyledTextContainer>
			{/* @ts-ignore */}
			<StyledImage />
			<StyledGradient />
		</StyledStudioAccessBanner>
	);
};

export default Banner;
