// @ts-nocheck
import styled from "rn-css";
import { globalAny } from "../../utils/Utils";
import { light_black, white_smoke } from "../../StyleHelpers";
import { useState } from "react";
import { DeviceSelectionPages } from "../../Types";
import { TouchableOpacity, Text } from "react-native";
import TopAppbar from "../../components/TopAppBar";
import ridevueLogo from "../../assets/Logo/iconLogo.svg";
import TouchableButton from "../../components/TouchableButton";
import ComponentThemeType from "../../models/ComponentTheme";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import backIcon from "../../assets/TopNavBarIcon/icon_nav_back.svg";
import closeIcon from "../../assets/TopNavBarIcon/icon_nav_close.svg";
import useGenericContentStore from "../../store/genericContent.store";
import { SafeAreaView } from "react-native-safe-area-context";

const StyledContainer = styled.View`
	background-color: ${light_black};
	height: 100%;
`;

const StyledOptionContainer = styled.View`
	top: 180px;
	margin-left: 190px;
	margin-right: 190px;
	margin-bottom: 10px;
	z-index: -1;
`;

const ScreenSelectionContainer = styled.View`
	font-size: 25px;
	color: ${white_smoke};
	gap: 24px;
	display: flex;
	flex-direction: row;
	justify-content: center;
`;

const ScreenSelectionBox = styled.View<any>`
	width: 466px;
	height: 424px;
	border-radius: 16px;
	border: ${(props) => (props?.selected ? "2px solid #008BEF" : 0)};
	background-color: ${(props) => (props?.selected ? "#333333" : "#222222")};
	background-image: ${(props) => (props?.selected ? "linear-gradient(90deg, rgba(0, 139, 239, 0.50) 0%, rgba(51, 51, 51, 0.50) 100%)" : "")};

	&:hover {
		border: ${(props) => (props?.selected ? "2px solid #008BEF" : "1px solid #fff")};
		background-color: ${(props) => (props?.selected ? "#333333" : "#333333")};
	}
`;

const ButtonContainer = styled.View`
	margin-top: 48px;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 50px;
`;

const ScreenInnerContainer = styled.View`
	display: flex;
	flex-direction: column;
`;

const ScreenInner = styled.View`
	width: auto;
	height: 320px;
	background-color: black;
	border-radius: 16px;
	padding: 24px;
	gap: 16px;
`;

const ScreenInnerText = styled.Text`
	font-size: 25px;
	font-style: normal;
	font-weight: 500;
	color: #f2f2f2;
`;

const TextContainer = styled.View`
	display: flex;
	justify-content: center;
	align-items: start;
	height: 100px;
	padding: 24px;
`;

const ScreenItemContainer = styled.View`
	display: flex;
	flex-direction: row;
	gap: 16px;
`;

const DemoOptions = (props: any) => {
	const setNumberOfScreens = useGenericContentStore((state: any) => state.setNumberOfScreens);
	const numberOfScreens = useGenericContentStore((state: any) => state.numberOfScreens);
	const [selectedScreen, setSelectedScreen] = useState(numberOfScreens - 1);
	const [screenHoverId, setScreenHoverId] = useState(-1);
	const ScreenWrapper = styled.View<any>`
		width: ${(props) => (props.width ? props.width : "100%")};
		height: 80px;
		border-radius: 8px;
		background-color: ${(props) => (props.selected ? "#333" : props.hover ? "#333" : "#222")};
		border-style: dashed;
		border-color: #f2f2f2;
		border-width: ${(props) => (props?.border ? "1px" : 0)};
	`;
	const selectionItems = [
		{
			title: "3 Screens (MHU, Left Rear, and Right Rear)",
			numberOfScreens: 3,
		},

		{
			title: "1 Screen (MHU)",
			numberOfScreens: 1,
		},
	];

	const SelectionBox = (items: any, selected: boolean) => {
		if (items === 0) {
			return (
				<>
					{/* @ts-ignore */}
					<ScreenInner>
						<ScreenWrapper hover={screenHoverId === 0} selected={selected} />
						{/* @ts-ignore */}
						<ScreenItemContainer>
							<ScreenWrapper hover={screenHoverId === 0} selected={selected} width={"201px"} />
							<ScreenWrapper hover={screenHoverId === 0} selected={selected} width={"201px"} />
						</ScreenItemContainer>
						{/* <ScreenWrapper hover={screenHoverId === 0 ? true : false} selected={selected} border={true} width={"201px"} /> */}
					</ScreenInner>
				</>
			);
		}

		return (
			<>
				{/* @ts-ignore */}
				<ScreenInner>
					<ScreenWrapper hover={screenHoverId === 1} selected={selected} />
					{/* <ScreenWrapper hover={screenHoverId === 1 ? true : false} selected={selected} border={true} width={"201px"} /> */}
				</ScreenInner>
			</>
		);
	};

	const renderSelectionBox = (items: any) => {
		return items.map((item: any, index: number) => (
			<TouchableOpacity
				key={`demo-option-${item.title}`}
				onPress={() => {
					setSelectedScreen(item.numberOfScreens);
					setNumberOfScreens(item.numberOfScreens);
				}}
			>
				{/* @ts-ignore */}
				<ScreenSelectionBox
					onMouseEnter={() => setScreenHoverId(index)}
					onMouseLeave={() => setScreenHoverId(index)}
					selected={selectedScreen === item.numberOfScreens}
				>
					{/* @ts-ignore */}
					<ScreenInnerContainer>
						{SelectionBox(index, selectedScreen === item.numberOfScreens)}

						{/* @ts-ignore */}
						<TextContainer>
							{/* @ts-ignore */}
							<ScreenInnerText>{item.title}</ScreenInnerText>
						</TextContainer>
					</ScreenInnerContainer>
				</ScreenSelectionBox>
			</TouchableOpacity>
		));
	};

	return (
		//@ts-ignore
		<StyledContainer>
			<TopAppbar
				leftIcon={closeIcon}
				leftIconWidth={92}
				leftTitleIcon={backIcon}
				screenTitle={globalAny.language.demo_options}
				rightLogo={ridevueLogo}
				onPressLeftTitleIcon={props?.onPressLeftTitleIcon}
				onPressLeftIcon={props?.onPressLeftIcon}
				isSingleLogo={true}
				demoOptions={true}
			/>
			<SafeAreaView>
				{/* @ts-ignore */}
				<StyledOptionContainer>
					{/* @ts-ignore */}
					<ScreenSelectionContainer>{renderSelectionBox(selectionItems)}</ScreenSelectionContainer>
					{/* @ts-ignore */}
					<ButtonContainer>
						<TouchableButton
							style={{ width: 956 }}
							componentTheme={ComponentThemeType.VinFast}
							type={ComponentTypeEnum.Primary}
							onPress={() => {
								props?.setSelectedView(DeviceSelectionPages.ScreenSelectionLoader);
								setTimeout(() => props?.setSelectedView(DeviceSelectionPages.ScreenSelection), 1000);
							}}
							disabled={selectedScreen < 0}
						>
							<Text>{globalAny.language.continue}</Text>
						</TouchableButton>
					</ButtonContainer>
				</StyledOptionContainer>
			</SafeAreaView>
		</StyledContainer>
	);
};

export default DemoOptions;
