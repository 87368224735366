import styled, { css } from "styled-components/native";
import { sm, md, white_smoke } from "../../StyleHelpers";
import styledRN from "rn-css";
import { globalAny, metadataTextSeparator } from "../../utils/Utils";
import { Image, TouchableOpacity } from "react-native";
import GenresComponent from "../../components/GenresComponent";
import Text from "../../components/Text/Text";
import { useEffect, useState } from "react";

interface Props {
	metadata?: any;
	price?: any;
	isRent?: any;
	onPressTermsAndService?: () => void;
}

interface TextProps {
	bold?: boolean;
	fontWeight?: number;
	underline?: boolean;
}

const StyledContainer = styled.View`
	margin-top: 40px;
	display: flex;
	flex-direction: row;
	height: 400;
`;

const StyledDetailsContainer = styled.View`
	width: 75%;
	overflow-y: scroll;
`;

const StyledTitleContainer = styled.View`
	display: flex;
	flex-direction: column;
`;

const StyledPackshotContainer = styled.View`
	width: 25%;
	display: flex;
	justify-content: center;
	align-items: end;
`;

const StyledDetails = styled.View`
	width: 80%;
`;

const StyledText = styled.Text<TextProps>`
	color: ${white_smoke};

	${(props) =>
		props.bold &&
		css`
			font-weight: 700;
			font-size: 25px;
		`}

	${(props) =>
		props.fontWeight &&
		css`
			font-weight: ${props.fontWeight};
			font-size: 25px;
		`}

    ${(props) =>
		props.underline &&
		css`
			text-decoration: underline;
			text-decoration-color: ${white_smoke};
		`}
`;

const StyledTitle = styledRN.Text`
	font-size: 50px;
	line-height: 60px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;

	@media (min-width:  ${sm}) {
		width: 420px;
	}

	@media (min-width:  ${md}) {
		width: 500px;
	}

`;

const StyledTextContainer = styled.View`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

const StyledMargin = styledRN.View`
	margin-top: 20px;

	@media (min-width: 0) {
		width: 70%;
	}

	@media (min-width: ${sm}) {
		width: 80%;
	}
`;

const PurchaseOptions = (props: Props) => {
	const wording = {
		purchasePrice: props.isRent ? globalAny.language.rent_price : globalAny.language.purchase_price,
		tax: props?.price?.taxType === 1 ? globalAny.language.tax : globalAny.language.vat,
		total: globalAny.language.total,
		termOfService: globalAny.language.purchase_agree,
	};

	const purchaseDetails = [
		{
			id: 1,
			title: wording.purchasePrice,
			price: props?.price?.price,
			bold: true,
		},
		{
			id: 2,
			title: `${wording.tax}`,
			price: props?.price?.taxAmount,
			bold: false,
			fontWeight: 400,
		},
		{
			id: 3,
			title: wording.total,
			price: props?.price?.totalAmount,
			bold: true,
		},
	];

	const [image, setImage] = useState();

	useEffect(() => {
		let getImage: any;
		if (props?.metadata?.packshots) {
			getImage = props?.metadata?.packshots?.filter((val: any) => val.width === 600 && val.height === 900)[0]?.url;
			setImage(getImage);
			return;
		}
		getImage = props?.metadata?.images?.filter((val: any) => val.width === 600 && val.height === 900)[0]?.url;
		setImage(getImage);
	}, []);

	const renderPurchaseDetails = (item: any) => (
		// @ts-ignores
		<StyledTextContainer key={`purchase-details-${item.id}`}>
			{/* @ts-ignore */}
			<StyledText bold={item.bold} fontWeight={item.fontWeight}>
				{item.title}
			</StyledText>
			{/* @ts-ignore */}
			<StyledText bold={item.bold} fontWeight={item.fontWeight}>
				{item.price}
			</StyledText>
		</StyledTextContainer>
	);

	return (
		//@ts-ignore
		<StyledContainer>
			{/* @ts-ignore */}
			<StyledDetailsContainer>
				{/* @ts-ignore */}
				<StyledText>
					{/* @ts-ignore */}
					<StyledTitleContainer>
						{/* @ts-ignore */}
						<StyledTitle>
							<Text style={{ fontSize: 50, lineHeight: 60, width: 500 }} numberOfLines={1} ellipsizeMode="tail">
								{props?.metadata?.title}
							</Text>
						</StyledTitle>

						{/* @ts-ignore */}
						<StyledDetails>
							<GenresComponent rating={props?.metadata?.rating} genres={props?.metadata?.genres} />
							{metadataTextSeparator(props?.metadata)}
						</StyledDetails>
					</StyledTitleContainer>
					{/* @ts-ignore */}
					<StyledMargin>
						{/* @ts-ignore */}
						{purchaseDetails.map((item) => {
							return renderPurchaseDetails({
								...item,
							});
						})}
					</StyledMargin>
					{/* @ts-ignore */}
					<StyledMargin>
						{/* @ts-ignore */}
						<StyledDetails>
							{/* @ts-ignore */}
							<StyledText fontWeight={400}>{`${wording.termOfService} `}
							<TouchableOpacity onPress={props.onPressTermsAndService}>
								{/* @ts-ignore */}
								<StyledText fontWeight={400} underline={true}>
									{globalAny.language.terms_of_service}.
								</StyledText>
							</TouchableOpacity>
							</StyledText>
						</StyledDetails>
					</StyledMargin>
				</StyledText>
			</StyledDetailsContainer>
			{/* @ts-ignore */}
			<StyledPackshotContainer>
				<Image
					source={{
						uri: `${image}?width=600&disablewebp=false`,
					}}
					style={{ width: 270, height: "100%", borderRadius: 16, borderColor: "#555454", borderWidth: 1 }}
					resizeMode="cover"
				/>
			</StyledPackshotContainer>
		</StyledContainer>
	);
};

export default PurchaseOptions;
