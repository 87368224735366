import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import { Autoplay, Pagination } from "swiper";
import { useState } from "react";
import { white_smoke, dark_gray } from "../../StyleHelpers";
import { StyleSheet } from "react-native";
import TouchableOpacity from "../../components/TouchableOpacity/TouchableOpacity";
import Image from "../../components/Image/Image";
import { calculateImageWidth } from "../../utils/Utils";

interface Props {
	item: any;
	onPress: (product: any) => void;
}

const swiperConfig = {
	style: { width: "100%", backgroundColor: "transparent" },
	slidesPerView: 1,
	spaceBetween: 60,
	pagination: {
		clickable: true,
	},
};

const Banner = (props: Props) => {
	const [borderEvent, setBorderEvent] = useState(false);

	return (
		<Swiper
			{...swiperConfig}
			allowTouchMove={true}
			autoplay={{
				delay: 8000,
				disableOnInteraction: false,
			}}
			modules={[Autoplay, Pagination]}
		>
			{props.item.isBanner &&
				props.item?.products?.map((product: any) => {
					return (
						<SwiperSlide key={`swiper-product-${product.parentProductId}`}>
							<TouchableOpacity
								activeOpacity={1}
								onPress={() => props.onPress(product.parentProductId)}
								onMouseEnter={() => setBorderEvent(true)}
								onMouseLeave={() => setBorderEvent(false)}
							>
								<Image
									source={{
										uri: `${product.packshots}?width=${calculateImageWidth(false, true)}&disablewebp=false`,
									}}
									
									resizeMode="stretch"
									style={borderEvent ? styles.onHover : styles.onLeave}
								/>
							</TouchableOpacity>
						</SwiperSlide>
					);
				})}
		</Swiper>
	);
};

export default Banner;

const styles = StyleSheet.create({
	onHover: {
		marginLeft: 147,
		marginRight: 20,
		aspectRatio: 4 / 1,
		borderRadius: 16,
		outlineStyle: "solid",
		outlineColor: white_smoke,
		outlineWidth: 2,
		outlineOffset: -2,
		transition: "outline-color 0.6s ease",
		backgroundColor: dark_gray
	},
	onLeave: {
		marginLeft: 147,
		marginRight: 20,
		borderRadius: 16,
		aspectRatio: 4 / 1,
		outlineStyle: "solid",
		outlineColor: "rgba(255, 255, 255, 0.30)",
		outlineWidth: 2,
		outlineOffset: -2,
		backgroundColor: dark_gray
	},
});
