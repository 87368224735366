import { dark_gray, spacing_l, spacing_s, white_smoke } from "../../StyleHelpers";
import styled, { css } from "styled-components/native";
import { View, Text } from "react-native";
import TouchableButton from "../../components/TouchableButton";
import ComponentThemeType from "../../models/ComponentTheme";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import { useNavigation } from "@react-navigation/native";
import { routeNewCuration } from "../../Routes";
import { globalAny } from "../../utils/Utils";
import TouchableOpacity from "../../components/TouchableOpacity/TouchableOpacity";
import { useState } from "react";

interface Props {
	banner?: any;
	borderEvent?: boolean;
}

const StyledContainer = styled.View<Props>`
	margin: ${spacing_s} ${spacing_l} 81px ${spacing_l};
	padding: 55px 0 0 98px;
	background-color: ${dark_gray};
	${(props) =>
		props.banner &&
		css`
			background-image: url(${props.banner});
			background-repeat: no-repeat;
			background-size: cover;
		`}

	${(props) =>
		props.borderEvent &&
		css`
			transition: border-color 0.6s ease;
			border-color: ${white_smoke};
		`}

	${(props) =>
		!props.borderEvent &&
		css`
			border-color: rgba(255, 255, 255, 0.3);
		`}

	border-radius: 16px;
	height: 380px;
	border-width: 2px;
`;

const StyledButton = styled.View`
	margin-top: ${spacing_s};
	width: 368px;
	margin-bottom: 112px;
`;

const HeroBanner = (props: Props) => {
	const navigation = useNavigation();
	const [borderEvent, setBorderEvent] = useState(false);

	const goToStudioAccessHandler = () => {
		//@ts-ignore
		navigation.navigate(routeNewCuration);
	};

	return (
		<View style={{ marginLeft: 114, marginTop: 60 }}>
			<TouchableOpacity onMouseEnter={() => setBorderEvent(true)} onMouseLeave={() => setBorderEvent(false)}>
				{/* @ts-ignore */}
				<StyledContainer borderEvent={borderEvent} banner={props.banner}>
					<Text style={{ width: "50%", color: "white", fontSize: 25 }}>
						{globalAny.language.banner_text}
					</Text>
					{/* @ts-ignore */}
					<StyledButton>
						<TouchableButton
							addMarginRight={true}
							componentTheme={ComponentThemeType.VinFast}
							type={ComponentTypeEnum.Primary}
							onPress={goToStudioAccessHandler}
						>
							{globalAny.language.go_behind_the_scenes}
						</TouchableButton>
					</StyledButton>
				</StyledContainer>
			</TouchableOpacity>
		</View>
	);
};

export default HeroBanner;
