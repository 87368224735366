import { useEffect, useMemo, useState } from "react";
import MainLayoutWithSideNav from "../../components/Layout/MainLayoutWithSideNav";
import { AsyncStorageKeys, Categories } from "../../Types";
import { getTransactionFilters, globalAny } from "../../utils/Utils";
import CustomizeLoader from "../../components/Loaders/CustomizeLoader";
import defaultStudioAccessBanner from "../../assets/Images/StudioAccess.webp";
import ridevueLogo from "../../assets/Logo/ridevuLogo.svg";
import { useNavigation } from "@react-navigation/native";
import { routeSearchScreen, routeNewSettings } from "../../Routes";
import PlaylistContainer from "../../components/Layout/PlaylistContainer";
import useGenericContentStore from "../../store/genericContent.store";
import { AppLogger } from "../../utils/AppLogger";
import { getCategories } from "../../services/categoriesService";
import useSearchDataStore from "../../store/useSearchData.store";
import { getPage } from "../../services/pageService";
import Toast from "../../components/Toast";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";

const defaultItem = [
	{
		id: 1,
		isLandscape: true,
		packshot: [1, 2, 3, 4, 5],
	},
	{
		id: 2,
		isLandscape: false,
		packshot: [1, 2, 3, 4, 5, 6, 7],
	},
	{
		id: 3,
		isLandscape: false,
		packshot: [1, 2, 3],
	},
];

const Home = () => {
	const navigation = useNavigation();
	const setNavigateEvent = useGenericContentStore((state: any) => state.setNavigateEvent);
	const [categories, setCategories] = useState([]);
	const [isShowLoader, setIsShowLoader] = useState(true);
	const [isAggregate, setIsAggregate] = useState(false);
	const [startIndex, setStartIndex] = useState(5);
	const setLastSearchQuery = useSearchDataStore((state: any) => state.setLastSearchQuery);
	const setSearchResults = useSearchDataStore((state: any) => state.setSearchResults);
	const setLastNext = useSearchDataStore((state: any) => state.setLastNext);
	const [studioAccessBanner, setStudioAccessBanner] = useState(defaultStudioAccessBanner);
	const [isToast, setIsToast] = useState(true);
	const onDismissSnackBar = () => setIsToast(false);
	const setSelectedTabs = useGenericContentStore((state: any) => state.setSelectedTabs);
	const [isMHU, setIsMHU] = useState(false);

	const onSearch = () => {
		//@ts-ignore
		navigation.navigate(routeSearchScreen);
	};

	const onPressAddScreens = () => {
		setSelectedTabs(3);
		//@ts-ignore
		navigation.navigate(routeNewSettings);
	};

	const renderHomePlaylist = useMemo(
		() => (
			<PlaylistContainer
				categories={categories}
				isSeeMore={true}
				location={Categories.home}
				footerBanner={studioAccessBanner}
				transactionalFilters={getTransactionFilters()}
				setIsShowLoader={setIsShowLoader}
				setStartIndex={setStartIndex}
				startIndex={startIndex}
				setIsAggregate={setIsAggregate}
				isHome={true}
			/>
		),
		[categories, startIndex]
	);

	useEffect(() => {
		const controller = new AbortController();
		(async () => {
			try {
				const pages: any = await getPage(Categories.studioTwo, controller.signal);
				const [page] = pages.pages;
				const [pageImage] = page.pageImages;
				setStudioAccessBanner(pageImage.imageUrl);
			} catch (err) {
				AppLogger.log(err);
			}
		})();
		(async () => {
			try {
				let categories: any = (await getCategories(Categories.home, getTransactionFilters(), controller.signal)) ?? [];
				setCategories(categories);
			} catch (err) {
				AppLogger.log(err);
			}
		})();
		setNavigateEvent("");
		setLastSearchQuery("");
		setSearchResults([]);
		setLastNext(0);
		return () => {
			setCategories([]);
		};
	}, []);

	useEffect(() => {
		const getAddScreensCount = localStorage.getItem(AsyncStorageKeys.addScreenCount);
		if (getAddScreensCount) {
			const count = Number(getAddScreensCount);

			if (count >= 3) {
				setIsToast(false);
				return;
			}

			localStorage.setItem(AsyncStorageKeys.addScreenCount, (count + 1).toString());
			return;
		}
		localStorage.setItem(AsyncStorageKeys.addScreenCount, "1");
	}, []);

	useEffect(() => {
		const isInCarScreenAvailable = localStorage.getItem(AsyncStorageKeys.isInCarScreen) === "true";
		if (isInCarScreenAvailable) {
			setIsMHU(true);
			return;
		}
		setIsMHU(false);
	}, []);

	return (
		<MainLayoutWithSideNav searchIcon={true} navTitle={globalAny.language.header_home} titleLogo={ridevueLogo} onPressRightIcon={onSearch}>
			<CustomizeLoader show={isShowLoader} isHeroBanner={true} isPlaylist={true} data={defaultItem}></CustomizeLoader>
			{renderHomePlaylist}

			{isToast && !isShowLoader && !isAggregate && isMHU ? (
				<Toast
					visible={true}
					text={globalAny.language.connect_to_other_screens}
					label={globalAny.language.add_screen.toUpperCase()}
					onDismissSnackBar={() => onDismissSnackBar()}
					onPress={() => onPressAddScreens()}
					type={ComponentTypeEnum.Tertiary}
					duration={30000}
				/>
			) : (
				<></>
			)}
		</MainLayoutWithSideNav>
	);
};

export default Home;
