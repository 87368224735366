import { TextInput } from "react-native";
import React, { useEffect, useState } from "react";
import { calculateDynamicHeight, calculateDynamicWidth, calculateFontSize } from "../../utils/Utils";

const TextInputLegacy = React.forwardRef((props: any, ref) => {
	const baseTextFontSize = props.fontSize ? props.fontSize : 18;
	const [textFontSize, setTextFontSize] = useState(calculateFontSize(baseTextFontSize));
	const [width, setWidth] = useState(calculateDynamicWidth(props.width));
	const [height, setHeight] = useState(calculateDynamicHeight(props.height, props.width));
	const focused = props.focused;

	/**
	 * Dynamic Resizing Function - Updates the dimensions of items when the screen size changes without having to reload
	 */
	useEffect(() => {}, [props]);

	useEffect(() => {
		setTextFontSize(calculateFontSize(baseTextFontSize));
		setWidth(calculateDynamicWidth(props.width));
		setHeight(calculateDynamicHeight(props.height, props.width));
	}, [props.windowDimensions]);

	const defaultStyle = { color: "black", fontSize: textFontSize, width: width, height: height };
	const incomingStyle = Array.isArray(props.style) ? props.style : [props.style];
	return <TextInput autoFocus={focused} ref={ref} {...props} style={[defaultStyle, ...incomingStyle]} />;
});

TextInputLegacy.displayName = "TextInputLegacy";

export default TextInputLegacy;
