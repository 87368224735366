import { Animated, LayoutChangeEvent, View, Easing, TouchableOpacity } from 'react-native';
import { FC, memo, useEffect, useRef, useState } from 'react';
import useStyles, { circleSize } from './Marker.styles';

import type { IMarker } from './Marker.types';

const EASING = Easing.bezier(0.76, 0, 0.24, 1);

const Marker: FC<IMarker> = ({
  colors: {
    line = {
      normal: "rgba(242, 242, 242, 0.4)",
      active: "rgba(35, 98, 190, 1)",
      completed: "rgba(35, 98, 190, 1)"
    },
    text = {
      normal: 'transparent',
      active: 'transparent',
      completed: 'transparent',
    },
  } = {},
  stepState: {
    isActive,
    isCompleted,
    isFirstInteraction,
    isLast,
    stepIndex = 0,
  } = {},
  onPress
}) => {
  const [widthContainer, setWidthContainer] = useState(0);

  const barAnimation = useRef(new Animated.Value(0)).current;
  const borderColorAnimation = useRef(new Animated.Value(0)).current;
  const scaleAnimation = useRef(new Animated.Value(0)).current;

  useEffect(() => {
    Animated.parallel(
      [
        Animated.timing(isCompleted ? scaleAnimation : barAnimation, {
          toValue: isCompleted ? 1 : 0,
          duration: 300,
          easing: EASING,
          useNativeDriver: true,
        }),
        Animated.timing(isCompleted ? barAnimation : scaleAnimation, {
          toValue: isCompleted ? 1 : 0,
          duration: 300,
          delay: 150,
          easing: EASING,
          useNativeDriver: true,
        }),
      ],
      { stopTogether: false }
    ).start();
  }, [isCompleted]);

  useEffect(() => {
    Animated.timing(borderColorAnimation, {
      toValue: isActive ? 1 : 0,
      duration: 400,
      easing: EASING,
      useNativeDriver: true,
    }).start();
  }, [isActive]);

  const borderColor =
    isCompleted
      ? line.completed
      : borderColorAnimation.interpolate({
          inputRange: [0, 1],
          outputRange: [line.normal, line.completed],
        });

  const scale = isActive && isCompleted ? 1 : scaleAnimation;

  const barInterpolate =
    isFirstInteraction && isCompleted
      ? '100%'
      : barAnimation.interpolate({
          inputRange: [0, 1],
          outputRange: ['0%', '100%'],
        });

  const handleOnLayout = (el: LayoutChangeEvent) => {

    const { width } = el.nativeEvent.layout;

    setWidthContainer(width);
  };

  const styles = useStyles();

  return (
    <View onLayout={handleOnLayout} style={styles.container}>
      <TouchableOpacity onPress={() => onPress(stepIndex)}>
        <Animated.View style={[styles.circle, { borderColor, backgroundColor: isCompleted || (isCompleted && isLast) || isActive ? 'white' : 'transparent' }]}>
          <Animated.View
            style={[
              styles.circleInner,
              { transform: [{ scale }] },
            ]}
          />
        </Animated.View>
      </TouchableOpacity>
      {!isLast && (
        <View
          style={[
            styles.bar,
            { backgroundColor: line.normal },
            {
              transform: [
                {
                  translateX: widthContainer / 2,
                },
              ],
              backgroundColor: line.normal,
              width: widthContainer - circleSize,
            },
          ]}
        >
          <Animated.View
            style={[
              styles.barInner,
              { backgroundColor: line.active },
              { width: barInterpolate },
            ]}
          />
        </View>
      )}
    </View>
  );
};

export default memo(
  Marker,
  (prevProps, nextProps) =>
    prevProps.stepState?.isActive === nextProps.stepState?.isActive &&
    prevProps.stepState?.isCompleted === nextProps.stepState?.isCompleted &&
    prevProps.stepState?.isLast === nextProps.stepState?.isLast &&
    prevProps.stepState?.stepIndex === nextProps.stepState?.stepIndex
);
