import { useWindowDimensions } from "react-native";
import { light_black } from "../../StyleHelpers";
import ridevueLogo from "../../assets/Logo/logos.svg";
import Image from "../Image/Image";
import H3 from "../Typography/H3";
import FontWeightType from "../../models/FontWeightType";
import Spinner from "../Spinner";
import styled from "styled-components/native";

interface Props {
	show: boolean;
	text?: string;
	showSpinner?: boolean;
}

const StyledContainer = styled.View`
	width: 100%;
	height: 100%;
	background-color: ${light_black};
	align-items: center;
	color: white;
	z-index: 999;
`;

const StyledTextContainer = styled.View`
	align-items: center;
	justify-content: space-between;
	display: flex;
	margin-top: 4%;
`;

const StyledMain = styled.View`
	margin-top: 12.15%;
	align-items: center;
`;

const LoadingIndicator = ({ show, text, showSpinner }: Props) => {
	const windowDimensions = useWindowDimensions();

	return (
		<>
			{show ? (
				// @ts-ignore
				<StyledContainer>
					{/* @ts-ignore */}
					<StyledMain>
						<Image
							source={{ uri: ridevueLogo }}
							windowDimensions={windowDimensions}
							width="384px"
							height="223px"
							style={{ marginBottom: "4%" }}
						></Image>
						{/* @ts-ignore */}
						<StyledTextContainer>
							{text && <H3 fontWeight={FontWeightType.Light}>{text}</H3>}
							{showSpinner && <Spinner show={true} isStyle={true} />}
						</StyledTextContainer>
					</StyledMain>
				</StyledContainer>
			) : (
				<></>
			)}
		</>
	);
};

export default LoadingIndicator;
