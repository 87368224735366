import { NativeStackNavigationProp } from "react-native-screens/native-stack";
import IMovieDetails from "./models/IMovieDetails";
import IVamDetails from "./models/IVamDetails";

export const routeLogin = "Login";
export const routeHome = "Home";
export const routeNewHome = "NewHome";
export const routeScreenManager = "ScreenManager";
export const routeVamScreenManager = "VamScreenManager";
export const routeTripAssist = "TripAssist";
export const routeMyLibrary = "MyLibrary";
export const routeSettings = "OldSettings";
export const routeNewSettings = "Settings";
export const routeNewScreenManager = "NewScreenManager";
export const routeNewCuration = "ShortClips";
export const routePlayer = "Player";
export const routeVamPlayer = "VamPlayer";
export const routeTrailer = "Trailer";
export const routeYouTube = "YouTube";
export const routeTitleDetails = "TitleDetails";
export const routeTitleMetadata = "TitleMetadata";
export const routeGridView = "GridView";
export const routeQuickNav = "QuickNav";
export const routeProfileNameSelect = "ProfileNameSelect";
export const routeScreenManagerController = "ScreenManagerController";
export const routeCategoryPage = "CategoryPage";
export const routeCreateAccount = "CreateAccountScreen";
export const routeCreateAccountDetails = "CreateAccountDetailsScreen";
export const routeSignIn = "SignInScreen";
export const routeCodeSignIn = "CodeSignInScreen"
export const routeRecoverPassword = "RecoverPasswordScreen";
export const routeRedeemVoucher = "RedeemVoucherScreen";
export const routeDataManagementSettings = "SettingsDataManagement";
export const routeDataManagementSettingsDetails = "SettingsDataManagementDetails";
export const routeStyledComponentTest = "StyledComponentTest";
export const routeVinfastComponents = "VinfastComponents";
export const routeDevLoginScreen = "DevLoginScreen";
export const routeNewTitleDetails = "NewTitleDetails";
export const routeSearchScreen = "SearchScreen";
export const routeDeviceName = "DeviceName";
export const routeAggregatePage = "AggregatePages";
export const routeVamAggregatePage = "VamAggregatePages";
export const routePayment = "PaymentScreen";
export const routePage = "Page";
export const routePreview = "Preview";
export const routePrivacyPolicy = "PrivacyPolicyScreen";

/**
 * Types for the Player navigator stack
 */
type PlayerStackParamList = {
	Player: { parentProductId: string; streamUrl?: string; vamId?: number; shouldAutoPlay?: boolean; subTitle?: string; resumeProgress?: any };
};
export type PlayerScreenNavigationProp = NativeStackNavigationProp<PlayerStackParamList>;

type MovieStackParamList = {
	Player: IMovieDetails;
};
export type MovieNavigationProp = NativeStackNavigationProp<MovieStackParamList>;

type VamStackParamList = {
	VamPlayer: IVamDetails;
};
export type VamNavigationProp = NativeStackNavigationProp<VamStackParamList>;

/**
 * Types for the TitleDetails navigator stack
 */
type TitleDetailsStackParamList = {
	TitleDetails: { parentProductId: number };
};
export type TitleDetailsScreenNavigationProp = NativeStackNavigationProp<TitleDetailsStackParamList>;

/**
 * Types for the GridView navigator stack
 */
type GridViewStackParamList = {
	GridView: { categoryName: string; categoryTitle?: string; isVam?: boolean; playlistId?: string };
};
export type GridViewScreenNavigationProp = NativeStackNavigationProp<GridViewStackParamList>;

/**
 * Types for the StudioAccess navigator stack
 */
type CategoryPageNavParamList = {
	CategoryPage: { categoryName: string; categoryTitle?: string; isVam?: boolean; playlistId?: string; pageId?: string };
};
export type CategoryPageNavigationProp = NativeStackNavigationProp<CategoryPageNavParamList>;

/**
 * Types for the Home navigator stack
 */
type HomeStackParamList = {
	Home: {};
};
export type HomeScreenNavigationProp = NativeStackNavigationProp<HomeStackParamList>;

/**
 * Types for the Title Metadata navigator stack
 * comingFromHomeScreen is included in order to handle an edge case bug that causes navigation to leave the app if the user
 * tries an already active profile name twice in a row
 */
type ProfileNameSelectStackParamList = {
	ProfileNameSelect: { location: string };
};
export type ProfileNameSelectScreenNavigationProp = NativeStackNavigationProp<ProfileNameSelectStackParamList>;

/**
 * Types for the Player navigator stack
 */
type TitleMetadataStackParamList = {
	TitleMetadata: { backgroundImage: string; packshotImage: string; metadata: any; parentProductId: number; vamExist: boolean };
};
export type TitleMetadataScreenNavigationProp = NativeStackNavigationProp<TitleMetadataStackParamList>;

/**
 * Types for the Loginscreen navigator stack
 */
type LoginStackParamList = {
	Login: {};
};
export type LoginScreenNavigationProp = NativeStackNavigationProp<LoginStackParamList>;

/**
 * Types for the CreateAccount navigator stack
 */
type CreateAccountStackParamList = {
	CreateAccountScreen: {location: string, subLocation?: string};
};
export type CreateAccountScreenNavigationProp = NativeStackNavigationProp<CreateAccountStackParamList>;

/**
 * Types for the SignIn navigator stack
 */
type SignInStackParamList = {
	SignInScreen: {};
};
export type SignInScreenNavigationProp = NativeStackNavigationProp<SignInStackParamList>;

/**
 * Types for the Code SignIn navigator stack
 */
type CodeSignInStackParamList = {
	CodeSignInScreen: {};
};
export type CodeSignInScreenNavigationProp = NativeStackNavigationProp<CodeSignInStackParamList>;
/**
 * Types for the CreateAccount navigator stack
 */
type RecoverPasswordStackParamList = {
	RecoverPasswordScreen: {};
};
export type RecoverPasswordScreenNavigationProp = NativeStackNavigationProp<RecoverPasswordStackParamList>;

type RedeemVoucherStackParamList = {
	RedeemVoucherScreen: {};
};
export type RedeemVoucherScreenNavigationProp = NativeStackNavigationProp<RedeemVoucherStackParamList>;

type SettingsDataManagementStackParamList = {
	SettingsDataManagement: { location: string, subLocation?: string };
};
export type SettingsDataManagementNavigationProp = NativeStackNavigationProp<SettingsDataManagementStackParamList>;

type ScreenManagerStackParamList = {
	ScreenManager: { location: string; parentProductId: any; redirectTo?: any };
};
export type ScreenManagerNavigationProp = NativeStackNavigationProp<ScreenManagerStackParamList>;

type VamScreenManagerStackParamList = {
	VamScreenManager: { location: string; parentProductId: any; vamId: string; redirectTo?: any };
};
export type VamScreenManagerNavigationProp = NativeStackNavigationProp<VamScreenManagerStackParamList>;
/**
 * Types for the Settings navigator stack
 */
type SettingsStackParamList = {
	Settings: {};
};
export type SettingsNavigationProp = NativeStackNavigationProp<SettingsStackParamList>;
/**
 * Types for the TitleDetails navigator stack
 */
type ScreenManagerControllerStackParamList = {
	ScreenManagerController: {
		channelNumber: number;
		title: string;
		activeDevices: any;
		packshots: any;
		parentProductId: number;
		mqttCommand: any;
	};
};
export type ScreenManagerControllerScreenNavigationProp = NativeStackNavigationProp<ScreenManagerControllerStackParamList>;

type DevLoginScreenStackParamList = {
	DevLoginScreen: {};
};
export type DevLoginScreenNavigationProp = NativeStackNavigationProp<DevLoginScreenStackParamList>;

type PaymentScreenParamList = {
	PaymentScreen: {
		parentProductId: number;
		paymentType: string;
	};
};
export type PaymentScreenNavigationProp = NativeStackNavigationProp<PaymentScreenParamList>;

type PageParamList = {
	Page: {
		uniqueId: string;
	};
};
export type PageScreenNavigationProp = NativeStackNavigationProp<PageParamList>;
