import { useEffect, useState } from "react";
import { SafeAreaView, ScrollView, StyleSheet, View } from "react-native";
import { AsyncStorageKeys, CarActivity } from "../../Types";
import MainLayoutWithSideNav from "../../components/Layout/MainLayoutWithSideNav";
import TabsView from "../../components/TabsView";
import SettingAccount from "./SettingsAccount";
import SettingsHelpAndAbout from "./SettingsHelpAndAbout";
import TouchableButton from "../../components/TouchableButton";
import SettingsLegal from "./SettingsLegal";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import ComponentThemeType from "../../models/ComponentTheme";
import styled from "styled-components/native";
import { light_black, spacing_l } from "../../StyleHelpers";
import SettingsAdvance from "./SettingsAdvance";
import { useNavigation } from "@react-navigation/native";
import { routeHome, routeSearchScreen } from "../../Routes";
import { globalAny } from "../../utils/Utils";
import CarActivityDialog from "../../components/CarActivityDialog";
import useGenericContentStore from "../../store/genericContent.store";
import { DEFAULT_ENV } from "../../config/ENV_CONFIG";
import ENVIRONMENT from "../../config/ENVIRONMENT";
import useDrivingStore from "../../store/useDriving.store";
import SettingsAddScreen from "./SettingsAddScreen";
import useToggleStore from "../../store/useToggle.store";

const StyledButton = styled.View`
	width: 344px;
	padding-top: 40px;
	padding-bottom: 80px;
`;

const StyledButtonContainer = styled.View`
	justify-content: center;
	align-items: center;
	margin-bottom: 40px;
`;

const StyledContainer = styled.View`
	margin-left: ${spacing_l};
`;

const NewSettingsScreen = () => {
	const navigation = useNavigation();
	const isHide = useGenericContentStore((state: any) => state.isHide);
	const setIsHide = useGenericContentStore((state: any) => state.setIsHide);
	const isToggleAdvanceSettings = useToggleStore((state: any) => state.isToggleAdvanceSettings);
	const [modalLogout, setModalLogout] = useState(false);
	const [isMHU, setIsMHU] = useState(false);
	const menuList = [
		{ title: globalAny.language.account, disabled: false },
		{ title: globalAny.language.help_and_about, disabled: false },
		{ title: globalAny.language.legal, disabled: false },
		{ title: globalAny.language.add_screen, disabled: false },
		{ title: globalAny.language.advanced, disabled: false },
	];
	const carActivity = useDrivingStore((state: any) => state.carActivity);
	const selectedTabs = useGenericContentStore((state: any) => state.selectedTabs);
	const isEnabledFloatingButton = useGenericContentStore((state: any) => state.isEnabledFloatingButton);

	useEffect(() => {
		setIsHide(DEFAULT_ENV === ENVIRONMENT.PRODUCTION);
		setIsMHU(localStorage.getItem(AsyncStorageKeys.isInCarScreen) == "true");
	}, []);

	const LogOutAccount = () => {
		setModalLogout(true);

		setTimeout(() => {
			setModalLogout(false);
		}, 1000);
	};

	const filterMenu = (menuList: any) => {
		let filteredMenu = menuList;

		if (isHide || !isToggleAdvanceSettings) {
			filteredMenu = filteredMenu.filter((menu: any) => menu.title !== globalAny.language.advanced);
		}

		if (!isMHU) {
			filteredMenu = filteredMenu.filter((menu: any) => menu.title !== globalAny.language.add_screen);
		}

		return filteredMenu;
	};

	const onSearch = () => {
		//@ts-ignore
		navigation.navigate(routeSearchScreen);
	};

	return (
		<SafeAreaView style={{ flex: 1, backgroundColor: light_black, paddingTop: 112 }}>
			<MainLayoutWithSideNav
				screenTitle={globalAny.language.header_settings}
				navTitle={globalAny.language.header_settings}
				showLogout={modalLogout}
				searchIcon={true}
				onPressRightIcon={onSearch}
				isSettings={true}
			>
				<ScrollView>
					<View style={{ marginLeft: spacing_l, marginRight: spacing_l }}>
						<TabsView tabsToRender={filterMenu(menuList)} isSettings={true}>
							{/* @ts-ignores */}
							<StyledContainer>
								<SettingAccount></SettingAccount>
								{/* @ts-ignores */}
								<StyledButtonContainer>
									{/* @ts-ignores */}
									<StyledButton>
										<TouchableButton
											componentTheme={ComponentThemeType.VinFast}
											type={ComponentTypeEnum.Secondary}
											onPress={LogOutAccount}
										>
											{globalAny.language.logout}
										</TouchableButton>
									</StyledButton>
								</StyledButtonContainer>
							</StyledContainer>
							{/* @ts-ignores */}
							<StyledContainer>
								<SettingsHelpAndAbout />
							</StyledContainer>
							{/* @ts-ignores */}
							<StyledContainer>
								<SettingsLegal />
							</StyledContainer>
							{/* @ts-ignores */}
							<StyledContainer>{isMHU ? <SettingsAddScreen /> : <SettingsAdvance />}</StyledContainer>
							{/* @ts-ignores */}
							<StyledContainer>
								<SettingsAdvance />
							</StyledContainer>
						</TabsView>
					</View>
				</ScrollView>
			</MainLayoutWithSideNav>

			<CarActivityDialog
				title={globalAny.language.car_in_motion_title}
				body={globalAny.language.car_in_motion_body}
				buttonText={globalAny.language.car_in_motion_back_to_home}
				subText={globalAny.language.car_in_motion_subtext}
				onPress={() => {
					//@ts-ignore
					navigation.navigate(routeHome);
				}}
				show={carActivity === CarActivity.INMOTION && isEnabledFloatingButton && selectedTabs !== 3}
			/>
		</SafeAreaView>
	);
};

export default NewSettingsScreen;

const styles = StyleSheet.create({
	container: {
		flex: 1,
		backgroundColor: "#141414",
	},
});
