import { useEffect } from "react";
import { View } from "react-native";
import FlexRow from "../Layout/FlexRow";
import TouchableButton from "../TouchableButton";
import ComponentThemeType from "../../models/ComponentTheme";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import ButtonToggle from "../ButtonToggle";
import Text from "../Text/Text";
import styled from "rn-css";
import { create } from "zustand";
import { legalTitleFontSize, spacing_m, white_smoke } from "../../StyleHelpers";
import { globalAny } from "../../utils/Utils";
import WrapperCreateAccount from "../Layout/WrapperCreateAccount";
import CreateAccountButton from "../CreateAccountButton";
import PrivacyPolicyButton from "../PrivacyPolicyButton";
interface Props {
	onSubmit?: (value: boolean) => void;
	onChangeGoogleData?: (value: boolean) => void;
	onChangeVideoAnalyticsData?: (value: boolean) => void;
	onPressPrimaryButton?: () => void;
	onPressSecondaryButton?: () => void;
	onPressVideoAnalytics?: () => void;
	onPressGoogle?: () => void;
	onPressPrivacy?: () => void;
	showPrivacyLink?: boolean;
	isSettings?: boolean;
	title?: string;
	description?: string;
	googleAnalytics?: any;
	videoAnalytics?: any;
}

const StyledContainer = styled.View`
	z-index: -1;
	height: 100%;
`;

const StyledSwitchBodyStyle = styled.Text`
	font-size: 18px;
	margin: 5px 0 0 133px;
	color: ${white_smoke};
	font-weight: 500;
	@media (min-width: 1536px) {
		margin-top: 5px;
	}
	@media (max-height: 500px) and (min-height: 300px) {
		margin-top: -5px;
		font-size: 17px;
	}
	@media (max-height: 620px) and (min-height: 500px) {
		font-size: 14px;
	}

	@media (max-height: 900) and (min-height: 800px) {
		font-size: 22px;
	}
	@media (max-height: 1080px) and (min-height: 920px) {
		font-size: 25px;
		margin-top: 20px;
	}
`;

const StyledLegalTitleStyle = styled.Text`
	font-size: 37px;
	text-align: left;
	@media (min-width: 640px) {
		font-size: 32px;
	}
	@media (min-width: 1536px) {
		font-size: 37px;
	}
	@media (max-height: 800px) and (min-height: 500px) {
		font-size: 30px;
	}
`;

const StyledButtonContainer = styled.View<any>`
	margin: 0 14.1% 0 14.1%;
	width: 71.8%;
	position: absolute;
	bottom: 15%;

	@media (min-width: 1600px) and (max-width: 1600px) {
		position: relative;
		flex-direction: row;
		bottom: 2%;
		width: ${(props) => (props.showPrivacyLink ? "55%" : "")};
		margin-left: ${(props) => (props.showPrivacyLink ? "30%" : "")};
	}
`;

const StyledCreateButton = styled.View`
	margin-left: 133px;
	margin-top: 32px;
	@media (min-width: 640px) {
		margin-top: 5px;
		gap: 5px;
	}
	@media (min-width: 1536px) {
		margin-top: 32px;
	}
`;

const StyledDataContainer = styled.View<any>`
	opacity: ${(props) => (props.disabled ? 0.5 : 1)};
	@media (max-height: 610px) {
		margin-top: -70px;
	}
	@media (max-height: 610px) and (min-height: 500px) {
		margin-top: -50px;
	}
`;

const useOtherDataToggle = create((set: any) => ({
	otherData: false,
	googleData: false,
	videoAnalyticsData: false,
	setOtherData: (state: boolean) => set(() => ({ otherData: state })),
	setGoogleData: (state: boolean) => set(() => ({ googleData: state })),
	setVideoAnalyticsData: (state: boolean) => set(() => ({ videoAnalyticsData: state })),
}));

const OtherDataComponent = (props: Props) => {
	const { otherData, googleData, videoAnalyticsData, setOtherData, setGoogleData, setVideoAnalyticsData } = useOtherDataToggle();
	const getButtonTitle = (name: any, translatedName: string) => {
		try {
			const cacheAcceptOtherData = JSON.parse(localStorage.getItem("cacheAcceptOtherDataCreate") ?? "{}");
			const [title] =
				cacheAcceptOtherData?.sections[0]?.childSections[0]?.options
					?.find((option: any) => option?.name === name)
					.optionsWordings?.filter((val: any) => val?.key === "option-title") ?? {};
			return title?.textValue;
		} catch {
			return translatedName;
		}
	};

	const createAccountButtonData = [
		{
			title: getButtonTitle("Google Analytics", globalAny.language.google_analytics),
			isToggle: true,
			toggleTitle: googleData,
			onPress: props.onPressGoogle,
			onPressToggle: (state: boolean) => setGoogleData(state),
			disabled: !props.googleAnalytics,
		},
		{
			title: getButtonTitle("Video Analytics", globalAny.language.video_analytics),
			isToggle: true,
			toggleTitle: videoAnalyticsData,
			onPress: props.onPressVideoAnalytics,
			onPressToggle: (state: boolean) => setVideoAnalyticsData(state),
			disabled: !props.videoAnalytics,
		},
	];

	const touchableButtonData = [
		{
			title: globalAny.language.back,
			type: ComponentTypeEnum.Secondary,
			disabled: false,
			addMarginRight: true,
			onPress: props.onPressSecondaryButton,
		},
		{
			title: globalAny.language.next,
			type: "",
			disabled: props.isSettings,
			addMarginLeft: true,
			onPress: props.onPressPrimaryButton,
		},
	];

	useEffect(() => {
		if (googleData || videoAnalyticsData) {
			setOtherData(true);
		} else {
			setOtherData(false);
		}

		if (props.onSubmit) {
			props.onSubmit(googleData && videoAnalyticsData);
		}
	}, [googleData, videoAnalyticsData]);

	useEffect(() => {
		if (!props.onChangeGoogleData) {
			return;
		}

		// @ts-ignore
		props.onChangeGoogleData(googleData);
	}, [googleData]);

	useEffect(() => {
		if (!props.onChangeVideoAnalyticsData) {
			return;
		}

		// @ts-ignore
		props.onChangeVideoAnalyticsData(videoAnalyticsData);
	}, [videoAnalyticsData]);

	const handleOtherDataToggle = (state: boolean) => {
		setOtherData(state);
		if (state) {
			setGoogleData(true);
			setVideoAnalyticsData(true);
		} else {
			setGoogleData(false);
			setVideoAnalyticsData(false);
		}
	};

	const renderCreateAccountButton = ({ title, isToggle, toggleTitle, onPress, onPressToggle, disabled }: any) => (
		<CreateAccountButton
			key={`other-data-button-${title}`}
			title={title}
			isToggle={isToggle}
			toggleTitle={toggleTitle}
			onPress={onPress}
			onPressToggle={onPressToggle}
			disabled={disabled}
		/>
	);

	const renderTouchableButtonData = ({ title, type, addMarginRight, addMarginLeft, disabled, onPress }: any) => (
		<TouchableButton
			key={`other-data-touchable-${title}`}
			addMarginLeft={addMarginLeft}
			addMarginRight={addMarginRight}
			componentTheme={ComponentThemeType.VinFast}
			type={type}
			onPress={onPress}
			disabled={disabled}
		>
			{title}
		</TouchableButton>
	);

	return (
		//@ts-ignore
		<StyledContainer>
			<WrapperCreateAccount>
				<StyledDataContainer disabled={!props.videoAnalytics || !props.googleAnalytics}>
					{/* @ts-ignore */}
					<FlexRow>
						<ButtonToggle
							defaultValue={otherData}
							onPress={(state) => handleOtherDataToggle(state)}
							toggleDisabled={!props.videoAnalytics || !props.googleAnalytics}
						/>
						<View style={{ marginLeft: spacing_m }}>
							<Text fontSize={legalTitleFontSize}>
								{/* @ts-ignore */}
								<StyledLegalTitleStyle>{props.title ?? globalAny.language.other_data}</StyledLegalTitleStyle>
							</Text>
						</View>
					</FlexRow>

					{/* @ts-ignore   */}
					<StyledSwitchBodyStyle>{props.description ?? globalAny.language.other_data_body}</StyledSwitchBodyStyle>
				</StyledDataContainer>

				<StyledCreateButton>
					{createAccountButtonData.map((item) => {
						return renderCreateAccountButton(item);
					})}
				</StyledCreateButton>
			</WrapperCreateAccount>
			{/* @ts-ignore   */}
			<StyledButtonContainer showPrivacyLink={props.showPrivacyLink}>
				<FlexRow>
					{touchableButtonData.map((item) => {
						return renderTouchableButtonData(item);
					})}
				</FlexRow>
				{props.showPrivacyLink && <PrivacyPolicyButton onPress={props.onPressPrivacy} />}
			</StyledButtonContainer>
		</StyledContainer>
	);
};

export default OtherDataComponent;
