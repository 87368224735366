import { View } from "react-native";
import styled, { css } from "styled-components/native";
import { spacing_l, spacing_s } from "../../StyleHelpers";
import ImgProgressBar from "./ImgProgressBar";

interface Props {
	bottomSpace?: boolean;
}

const StyledLoadHeader = styled.View`
	width: 400px;
	height: 24px;
	border-radius: 16px;
	overflow: hidden;
	margin-bottom: ${spacing_s};
`;

const StyledLoadBody = styled.View`
	width: 300px;
	height: 18px;
	border-radius: 16px;
	overflow: hidden;
	margin: 0 0 ${spacing_s} 20px;
`;

const StyledLoadFooter = styled.View<Props>`
	width: 220px;
	height: 18px;
	border-radius: 16px;
	overflow: hidden;

	${(props) =>
		props.bottomSpace &&
		css`
			margin: 0 0 ${spacing_l} 20px;
		`}

	${(props) =>
		!props.bottomSpace &&
		css`
			margin: 0 0 0 20px;
		`}
`;

const TransactionHistoryLoader = () => {
	const loop = [1, 2, 3];
	return (
		<>
			{loop.map((val) => {
				return (
					<View key={`transaction-loader-${val}`}>
						{/* @ts-ignores */}
						<StyledLoadHeader>
							<ImgProgressBar />
						</StyledLoadHeader>
						{/* @ts-ignores  */}
						<StyledLoadBody>
							<ImgProgressBar />
						</StyledLoadBody>
						{/* @ts-ignores */}
						<StyledLoadFooter bottomSpace={val !== 3}>
							<ImgProgressBar />
						</StyledLoadFooter>
					</View>
				);
			})}
			;
		</>
	);
};

export default TransactionHistoryLoader;
