import { spacing_l, white_smoke, dark_gray } from "../../StyleHelpers";
import styled from "styled-components/native";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import "swiper/swiper.scss"; // core Swiper
import "swiper/modules/navigation/navigation.scss"; // Navigation module
import "swiper/modules/pagination/pagination.scss"; // Pagination module
import "swiper/modules/autoplay/autoplay.scss"; // Autoplay module
import { Pagination, Autoplay } from "swiper";
import { StyleSheet } from "react-native";
import { PageScreenNavigationProp, routePage, routeTitleDetails } from "../../Routes";
import { useNavigation } from "@react-navigation/native";
import Image from "../../components/Image/Image";
import { useState } from "react";
import TouchableOpacity from "../../components/TouchableOpacity/TouchableOpacity";
import { calculateImageWidth } from "../../utils/Utils";
import useGenericContentStore from "../../store/genericContent.store";
import { Pages } from "../../Types";
interface Props {
	isBanner?: any;
	key?: any;
	products?: any;
	heroBanner?: any;
	location?: any;
	title?: any;
	showTitle?: any;
	onPress?: () => void;
	isPreview?: boolean;
}

const StyledContainer = styled.View<Props>`
	padding: ${(props) => (props.heroBanner ? spacing_l : 0)} 0 0 0;
`;

const StyledMain = styled.View`
	display: flex;
	justify-content: center;
	align-items: center;
`;

const FeatureText = styled.View<any>`
	position: absolute;
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 0.5rem;
	top: 0px;
	left: 147px;
	padding: 5vh 5vw;
	max-width: 45%;
	height: 99.5%;
	z-index: 100;
	border-radius: 16px;
	background-image: linear-gradient(90deg, #131313 0%, rgba(19, 19, 19, 0.00) 100%);
	margin: 1px 1px 1px 1px;
`;

const FeatureLineOne = styled.Text<any>`
	font-size: 2.5vw;
	font-weight: 500;
	font-style: normal;
	line-height: 44px;
	color: ${(props) => props?.hexCode};
    text-overflow: ellipsis;
`;

const FeatureLineTwo = styled.Text<any>`
	font-size: 2vw;
	font-weight: 500;
	font-style: normal;
	line-height: 44px;
	color: ${(props) => props?.hexCode};
`;

const swiperConfig = {
	style: { width: "100%", backgroundColor: "transparent" },
	slidesPerView: 1,
	spaceBetween: 60,
	pagination: {
		clickable: true,
	},
};

const HeroBanner = (props: Props) => {
	const delay = 8000;
	const navigationTitleScreen = useNavigation<any>();
	const navigationPageScreen = useNavigation<PageScreenNavigationProp>();
	const [borderEvent, setBorderEvent] = useState(false);
	const setNavigateEvent = useGenericContentStore((state: any) => state.setNavigateEvent);
	const setSelectedTabs = useGenericContentStore((state: any) => state.setSelectedTabs);
	
	const onPress = (navigationLinkType: string, navigationLink: any) => {
		if (props?.isPreview) setNavigateEvent(Pages.preview);
		setSelectedTabs(0);
		switch(navigationLinkType) {
			case 'ParentProduct':
				navigationTitleScreen.push(routeTitleDetails, props?.isPreview ? { parentProductId: navigationLink, preview: true } : { parentProductId: navigationLink });
				break;
			case 'Page':
				navigationPageScreen.push(routePage, { uniqueId: navigationLink });
				break;
		}
	};

	const convertARGBtoRGBA = (code: any) => {
		if (!code) return;

		const hashtagLessCode = code.replace('#', '');
		const rgb = hashtagLessCode.substring(2);
		const alpha = hashtagLessCode.substring(0, 2);

		return `#${rgb}${alpha}`;
	};

	const renderFeatureText = (obj: any) => {
		const featureText = obj.featureText.filter((feature: any) => feature.text);
		const stt = obj?.sttUrl;
		let lineOne: any;
		let lineTwo: any;

		if (!featureText.length && !stt) return <></>;
		
		lineOne = featureText.find((feature: any) => feature.name === 'Line1');
		lineTwo = featureText.find((feature: any) => feature.name === 'Line2');

		return (
			<FeatureText>
				{stt 
					? <Image
							source={{
								uri: `${stt}?width=${calculateImageWidth(false, true)}&disablewebp=false`,
							}}
							resizeMode="contain"
							style={styles.stt}
						/>
					: <FeatureLineOne 
						hexCode={convertARGBtoRGBA(lineOne.hexCode)}>
							{lineOne.text}
					</FeatureLineOne>
				}
				{lineTwo ? <FeatureLineTwo 
					hexCode={convertARGBtoRGBA(lineTwo.hexCode)}>
						{lineTwo.text}
				</FeatureLineTwo> : <></>}
			</FeatureText>
		);
	};

	return (
		//@ts-ignore
		<StyledContainer>
			{/* @ts-ignore */}
			<StyledMain>
				<Swiper
					{...swiperConfig}
					allowTouchMove={true}
					autoplay={{
						delay: delay,
						disableOnInteraction: false,
					}}
					modules={[Autoplay, Pagination]}
				>
					{props?.heroBanner?.map((obj: any) => {
						return (
							<SwiperSlide key={`hero-banner${obj.featureId}`}>
								<TouchableOpacity
									activeOpacity={1}
									onPress={() => onPress(obj?.navigationLinkType, obj?.navigationLink)}
									onMouseEnter={() => setBorderEvent(true)}
									onMouseLeave={() => setBorderEvent(false)}
								>
									{renderFeatureText(obj)}
									<Image
										source={{
											uri: `${obj?.featureImageUrl}?width=${calculateImageWidth(false, true)}&disablewebp=false`,
										}}
										resizeMode="cover"
										style={borderEvent ? styles.onHover : styles.onLeave}
									/>
								</TouchableOpacity>
							</SwiperSlide>
						);
					})}
				</Swiper>
			</StyledMain>
		</StyledContainer>
	);
};

export default HeroBanner;

const styles = StyleSheet.create({
	stt: {
		width: 400,
		height: 100,
	},
	onHover: {
		marginLeft: 147,
		marginRight: 20,
		aspectRatio: 4 / 1,
		borderRadius: 16,
		outlineStyle: "solid",
		outlineColor: white_smoke,
		outlineWidth: 2,
		outlineOffset: -2,
		transition: "outline-color 0.6s ease",
		backgroundColor: dark_gray
	},
	onLeave: {
		marginLeft: 147,
		marginRight: 20,
		borderRadius: 16,
		aspectRatio: 4 / 1,
		outlineStyle: "solid",
		outlineColor: "rgba(255, 255, 255, 0.30)",
		outlineWidth: 2,
		outlineOffset: -2,
		backgroundColor: dark_gray
	},
});
