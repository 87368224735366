import { useEffect, useState } from "react";
import {
	LoginScreenNavigationProp,
	routeLogin,
	ProfileNameSelectScreenNavigationProp,
	routeProfileNameSelect,
	RecoverPasswordScreenNavigationProp,
	routeRecoverPassword,
	HomeScreenNavigationProp,
	routeHome,
} from "../../Routes";
import { AsyncStorageKeys, Pages, ProfileNameState } from "../../Types";
import { useNavigation } from "@react-navigation/native";
import { globalAny, capitalize, login, pushProfileNameStateToBrowserHistory, validateEmail, generateDeviceInfo } from "../../utils/Utils";
import { light_black } from "../../StyleHelpers";
import TouchableButton from "../../components/TouchableButton";
import ComponentThemeType from "../../models/ComponentTheme";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import TextInput from "../../components/TextInput";
import AsyncStorage from "@react-native-async-storage/async-storage";
import TopAppbar from "../../components/TopAppBar";
import ridevueLogo from "../../assets/Logo/iconLogo.svg";
import Toast from "../../components/Toast";
import styled from "styled-components/native";
import useGenericContentStore from "../../store/genericContent.store";
import closeIcon from "../../assets/TopNavBarIcon/icon_nav_close.svg";
import backIcon from "../../assets/TopNavBarIcon/icon_nav_back.svg";
import { SafeAreaView } from "react-native";
import SignInLoader from "../../components/Loaders/SignInLoader";
import useLoginStore from "../../store/useLogin.store";
import useSessionStore from "../../store/useSession.store";
import { getPresignedUrl } from "../../networking/networking";
import useMqttStore from "../../store/useMqtt.store";
import PrivacyPolicyButton from "../../components/PrivacyPolicyButton";
import PrivacyPolicy from "../../components/PrivacyPolicy";
import useLegalContentStore from "../../store/useLegalContent.store";

const StyledWrapper = styled.View`
	z-index: -1;
	top: 15vh;
	overflow: hidden;
	margin: 0 14.1% 70px 14.1%;
`;

const StyledButtonContainer = styled.View`
	flex-direction: row;
	width: 71.8%;
	position: absolute;
	bottom: 15%;
	margin: 0 14.1% 0 14.1%;
`;

const StyledFormContainer = styled.View`
	margin-top: 82px;
	flex-direction: row;
	width: 100%;
	gap: 10px;
`;

const StyledPrivacyButtonContainer = styled.View`
	position: absolute;
	bottom: 15%;
`;

const SignInScreen = () => {
	const navigationLoginScreen = useNavigation<LoginScreenNavigationProp>();
	const navigationHomeScreen = useNavigation<HomeScreenNavigationProp>();
	const navigationProfileNameSelectScreen = useNavigation<ProfileNameSelectScreenNavigationProp>();
	const navigationRecoverPasswordScreen = useNavigation<RecoverPasswordScreenNavigationProp>();
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [emailError, setEmailError] = useState(false);
	const [emailMessage, setEmailMessage] = useState("");
	const [passwordError, setPasswordError] = useState(false);
	const [passwordMessage, setPasswordMessage] = useState("");
	const [signInLoading, setSignInLoading] = useState(false);
	const [secureTextEntry, setSecureTextEntry] = useState(true);
	const [disableSignInBtn, setDisableSignInBtn] = useState(true);
	const [visible, setVisible] = useState(false);
	const [privacyPolicyText, setPrivacyPolicyText] = useState("");
	const [privacyPolicyScreen, setPrivacyPolicyScreen] = useState(false);
	const [toastMessage, setToastMessage] = useState("");
	const [labelText, setLabelText] = useState<any>();
	const [autoFocusPass, setAutoFocusPass] = useState(false);
	const mistakeCount = useGenericContentStore((state: any) => state.mistakeCount);
	const setMistakeCount = useGenericContentStore((state: any) => state.setMistakeCount);
	const setIsLogin = useGenericContentStore((state: any) => state.setIsLogin);
	const setLoginEmail = useGenericContentStore((state: any) => state.setLoginEmail);
	const onDismissSnackBar = () => setVisible(false);
	const setIsLoggedIn = useLoginStore((state: any) => state.setIsLoggedIn);
	const isLoggedIn = useLoginStore((state: any) => state.isLoggedIn);
	const isSigninLoading = useSessionStore((state: any) => state.isSigninLoading);
	const setIsSigninLoading = useSessionStore((state: any) => state.setIsSigninLoading);
	const setPresignedUrl = useMqttStore((state: any) => state.setPresignedUrl);
	const legalStoreValue = useLegalContentStore((state) => state.data);
	const setDeviceInfo = useMqttStore((state: any) => state.setDeviceInfo);
	const setNavigateEvent = useGenericContentStore((state: any) => state.setNavigateEvent);

	useEffect(() => {
		if (isLoggedIn && globalAny.profileName) {
			navigationHomeScreen.reset({
				index: 0,
				routes: [
					{
						name: routeHome,
					},
				],
			});
		}
	}, [isLoggedIn, globalAny.profileName]);

	useEffect(() => {
		(async () => {
			const resultLegal = await legalStoreValue.legalData[AsyncStorageKeys.legals];
			if (resultLegal) {
				if (privacyPolicyScreen) {
					let [data] = resultLegal?.legalContents?.filter((val: any) => val?.type === 2) ?? [];
					setPrivacyPolicyText(data?.htmlContent);
				}
			}
		})();
	}, [legalStoreValue.legalData, legalStoreValue, privacyPolicyScreen]);

	useEffect(() => {
		let unmounted = false;
		if (!unmounted) {
			const emailValidated = email.length && validateEmail(email);
			const passwordValidated = password.length > 0;

			setDisableSignInBtn(!emailValidated || !passwordValidated);
		}
		return () => {
			unmounted = true;
		};
	}, [email, password]);

	useEffect(() => {
		validateMistakeCount();
	}, [signInLoading]);

	const onPressEye = () => {
		if (secureTextEntry) {
			setSecureTextEntry(false);
			return;
		}
		setSecureTextEntry(true);
	};

	const onBackPress = () => {
		if (privacyPolicyScreen) {
			setPrivacyPolicyScreen(false);
			return;
		}
		//@ts-ignore
		navigationLoginScreen.navigate(routeLogin);
	};

	const onEnterEmail = (event: any) => {
		setEmail(event);

		if (!event) {
			setEmailError(false);

			return;
		}

		if (validateEmail(event)) {
			setEmailError(false);

			return;
		}

		setEmailMessage(globalAny.language.invalid_email);
		setEmailError(true);
	};

	const onEnterPassword = (event: any) => {
		setPassword(event);
		if (event.length > 0) {
			setPasswordError(false);
			return;
		}

		setPasswordMessage(globalAny.language.invalid_password);
		setPasswordError(true);
	};

	const invalidCredentials = () => {
		setIsSigninLoading(false);
		setToastMessage(globalAny.language.toast_invalid_credential);
		setLabelText("");
		setVisible(true);
		setPassword("");
		setDisableSignInBtn(true);
		setAutoFocusPass(false);
		setMistakeCount(mistakeCount + 1);
	};

	const networkConnectionLost = () => {
		setLabelText(globalAny.language.label_retry);
		setIsSigninLoading(false);
		setToastMessage(globalAny.language.error_signin);
		setVisible(true);
	};

	const RecoverPassword = () => {
		navigationRecoverPasswordScreen.navigate(routeRecoverPassword, { settings: "recover" });
	};

	const validateMistakeCount = () => {
		setLabelText(globalAny.language.label_retry);
		if (mistakeCount > 2) {
			const allowUntil = new Date().setMinutes(new Date().getMinutes() + 1);
			AsyncStorage.setItem(AsyncStorageKeys.allowUntil, allowUntil.toString());
			setLabelText(null);
			setToastMessage(globalAny.language.maximum_attempt);
			setEmail("");
		}
	};

	const onPressToast = (command: string) => {
		if (command === globalAny.language.label_retry && password && !passwordError) {
			setVisible(true);
			setIsSigninLoading(true);
			loginAccount();
		}
	};

	const setProfileName = async (deviceInfo: any) => {
		await AsyncStorage.setItem(AsyncStorageKeys.deviceId, deviceInfo.deviceId);
		globalAny.profileName = deviceInfo.deviceName;
	};

	const updateDevice = (profileName: any) => {
		const deviceInfo = generateDeviceInfo(profileName);
		setDeviceInfo(deviceInfo);
		setProfileName(deviceInfo);
	};

	const validateDeviceScreen = () => {
		const isInCarScreen = localStorage.getItem(AsyncStorageKeys.isInCarScreen) == "true";

		if (isInCarScreen) {
			const profileName = AsyncStorage.getItem(AsyncStorageKeys.profileName);
			setIsLoggedIn(true);
			updateDevice(profileName);
			navigationHomeScreen.reset({
				index: 0,
				routes: [
					{
						name: routeHome,
					},
				],
			});
			return;
		}

		navigationProfileNameSelectScreen.navigate(routeProfileNameSelect, { location: Pages.main });
	};

	const processLogin = async () => {
		const presignedService = await getPresignedUrl();
		setPresignedUrl(presignedService);
		setIsLogin(true);
		setIsSigninLoading(false);
		pushProfileNameStateToBrowserHistory(ProfileNameState.profileNameSelect);
		setMistakeCount(0);
		AsyncStorage.setItem(AsyncStorageKeys.email, email);
		validateDeviceScreen();
	};

	const loginAccount = async () => {
		if (!email || emailError) {
			setEmailMessage(globalAny.language.invalid_email);
			setEmailError(true);
			return;
		} 
		
		if (!password || passwordError || password.length === 0) {
			setPasswordMessage(globalAny.language.invalid_password);
			setPasswordError(true);
			return;
		}
		setNavigateEvent("");
		setIsSigninLoading(true);

			setLoginEmail(email);
			let initialized = await login(
				email,
				password,
				setIsLoggedIn,
				() => invalidCredentials(),
				() => networkConnectionLost()
			);
			if (initialized?.proceed) {
				await processLogin();
			}
	};

	const handleKeyPress = ({ nativeEvent: { key: keyValue } }: any) => {
		if (keyValue === "Enter") {
			setIsSigninLoading(false);
			loginAccount();
		}
	};

	const onClose = () => {
		navigationLoginScreen.navigate(routeLogin, {});
	};

	const onPrivacy = () => {
		setPrivacyPolicyScreen(true);
	};

	useEffect(() => {
		setSignInLoading(true);
		setTimeout(() => {
			setSignInLoading(false);
		}, 1000);
	}, []);

	if (signInLoading) {
		return <SignInLoader />;
	}

	return (
		<SafeAreaView style={{ flex: 1, backgroundColor: light_black, height: "100%" }}>
			<TopAppbar
				leftIcon={closeIcon}
				leftIconWidth={92}
				leftTitleIcon={backIcon}
				screenTitle={privacyPolicyScreen ? capitalize(globalAny.language.privacy_policy) : capitalize(globalAny.language.sign_in)}
				rightLogo={ridevueLogo}
				onPressLeftTitleIcon={onBackPress}
				onPressLeftIcon={onClose}
				isSingleLogo={true}
			/>

			{!privacyPolicyScreen && (
				<>
					{/* @ts-ignore */}
					<StyledWrapper>
						{/* @ts-ignore */}
						<StyledFormContainer>
							<TextInput
								componentTheme={ComponentThemeType.VinFast}
								placeholder={globalAny.language.email_placeholder}
								label={globalAny.language.enter_email_ca}
								onBlur={(e) => {
									const val = e.nativeEvent.text;
									onEnterEmail(val)
								}}
								error={emailError}
								errorMessage={emailMessage}
								autoFocus={true}
								disabled={isSigninLoading}
							/>
							<TextInput
								componentTheme={ComponentThemeType.VinFast}
								label={globalAny.language.enter_password}
								onChangeText={(event) => onEnterPassword(event)}
								onPressInputIcon={() => onPressEye()}
								inputIcon={secureTextEntry ? "eye" : "eye-off"}
								passwordField={secureTextEntry}
								error={passwordError}
								errorMessage={passwordMessage}
								autoFocus={autoFocusPass}
								value={password}
								onKeyPress={handleKeyPress}
								disabled={isSigninLoading}
							/>
						</StyledFormContainer>
					</StyledWrapper>
					{/* @ts-ignore */}
					<StyledButtonContainer>
						<TouchableButton
							addMarginRight={true}
							componentTheme={ComponentThemeType.VinFast}
							type={ComponentTypeEnum.Secondary}
							onPress={() => RecoverPassword()}
							disabled={isSigninLoading}
						>
							{capitalize(globalAny.language.recover_password)}
						</TouchableButton>
						<TouchableButton
							addMarginLeft={true}
							componentTheme={ComponentThemeType.VinFast}
							type={ComponentTypeEnum.Primary}
							onPress={() => {
								loginAccount();
							}}
						>
							{capitalize(globalAny.language.sign_in)}
						</TouchableButton>
					</StyledButtonContainer>
					{/* @ts-ignore */}
					<StyledPrivacyButtonContainer>
						<PrivacyPolicyButton onPress={onPrivacy} />
					</StyledPrivacyButtonContainer>
				</>
			)}

			{privacyPolicyScreen ? <PrivacyPolicy text={privacyPolicyText} /> : <></>}

			{visible ? (
				<Toast
					visible={visible}
					text={toastMessage}
					label={labelText}
					onDismissSnackBar={onDismissSnackBar}
					onPress={() => onPressToast(labelText)}
				/>
			) : (
				<></>
			)}
		</SafeAreaView>
	);
};

export default SignInScreen;
