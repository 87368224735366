import { View } from "react-native";
import styled from "styled-components/native";
import ImgProgressBar from "./ImgProgressBar";
import { spacing_l, spacing_m, spacing_s } from "../../StyleHelpers";

interface Props {
	show?: boolean;
	data?: any;
	hideTitle?: boolean;
}

const StyledSwiperContainer = styled.View`
	margin: 0 0 ${spacing_l} ${spacing_l};
`;

const StyledLoadingContainer = styled.View`
	flex-direction: column;
	margin-bottom: 88px;
`;

const StyledLoading = styled.View`
	margin-right: ${spacing_m};
	border-radius: 16px;
	overflow: hidden;
	width: 25vw;
	height: auto;
	aspect-ratio: 16 / 9;
`;

const StyledTitle = styled.View`
	margin: 40px 0 ${spacing_s} 0;
	height: 24px;
	border-radius: 12px;
	overflow: hidden;
	width: 368px;
`;

const IntroText = styled.View`
	margin-top: 15px;
	height: 16px;
	border-radius: 8px;
	overflow: hidden;
	width: 40vw;
`;

const StyledLoaderGrid = styled.View`
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	overflow: hidden;
`;

const IntroContainer = styled.View`
	margin-top: 30px;
	display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
	overflow: hidden;
	justify-content: space-between;
`;

const IntroImage = styled.View`
	margin-right: ${spacing_m};
	border-radius: 16px;
	overflow: hidden;
	width: 30vw;
	height: 30vh;
	margin-left: 315px;
`;

const IntroTextContainer = styled.View`
	width: 10vw;
	height: 2vh;
	margin-top: 2vh;
`;

const StyledVamTitle = styled.View`
	margin-top: 10px;
	width: 15vw;
	height: 20px;
	border-radius: 8px;
	overflow: hidden;
`;


const StudioLoader = (props: Props) => {
	if (props.show) {
		return (
			<View style={{ marginLeft: 115, marginTop: 112 }}>
				{/* @ts-ignore */}
				<StyledSwiperContainer>
					{/* @ts-ignore */}
					<IntroContainer>
						{/* @ts-ignore */}
						<IntroTextContainer>
							{/* @ts-ignore */}
							<IntroText>
								<ImgProgressBar />
							</IntroText>
							{/* @ts-ignore */}
							<IntroText>
								<ImgProgressBar />
							</IntroText>
							{/* @ts-ignore */}
							<IntroText>
								<ImgProgressBar />
							</IntroText>
							{/* @ts-ignore */}
							<IntroText style={{ width: 300 }}>
								<ImgProgressBar />
							</IntroText>
						</IntroTextContainer>

						{/* @ts-ignore */}
						<IntroImage>
							<ImgProgressBar />
						</IntroImage>
					</IntroContainer>

					{props.data?.map((val: any) => {
						return (
							<View key={`studio-loader-${val.id}`} style={{marginTop: -20}}>
								{!props.hideTitle && (
									// @ts-ignore
									<StyledTitle key={`studio-loader-title-${val.id}`} >
										<ImgProgressBar />
									</StyledTitle>
								)}
								{props.hideTitle && <View style={{ marginBottom: spacing_m }}></View>}
								{/* @ts-ignore  */}
								<StyledLoaderGrid  key={`studio-loader-grid-${val.id}`} >
									{val?.packshot.map((item: number) => {
										return (
											// @ts-ignore
											<StyledLoadingContainer key={`studio-loader-container-${val.id}-${item}`} >
												{/* @ts-ignore */}
												<StyledLoading key={`studio-grid-${val.id}-${item}`} isLandscape={val?.isLandscape}>
													<ImgProgressBar />
												</StyledLoading>
												{/* @ts-ignore */}
												<StyledVamTitle key={`studio-title-${val.id}-${item}`}>
													<ImgProgressBar />
												</StyledVamTitle>
											</StyledLoadingContainer>
										);
									})}
								</StyledLoaderGrid>
								{/* @ts-ignore  */}
								<StyledLoaderGrid>
								</StyledLoaderGrid>
							</View>
						);
					})}
				</StyledSwiperContainer>
			</View>
		);
	}
	return null;
};

export default StudioLoader;