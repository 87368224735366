import { useEffect, useState } from "react";
import { AppLogger } from "../../utils/AppLogger";
import { v4 as uuidv4 } from "uuid";
import { VERSION } from "../../utils/Utils";
import { AsyncStorageKeys } from "../../Types";
import CustomizeDialogBox from "./CustomizeDialogBox";
import styled from "styled-components/native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { DebugDetails, initialDetails } from "../../models/DebugDetails";
import useGenericContentStore from "../../store/genericContent.store";
import { ScrollView } from "react-native";

interface CustomDialogProps {
	onDismiss?: () => void;
}
const StyledContainer = styled.View`
	position: relative;
	justify-content: flex-start;
	align-items: flex-start;
	padding-left: 20px;
	width: 100%;
`;
const StyledText = styled.Text`
	font-size: 1vw;
	line-height: 5vh;
	color: white;
	text-align: left;
	overflow: hidden;
	text-wrap: wrap;
	width: 100%;
`;

const DebugDialog = (props: CustomDialogProps) => {
	const [details, setDetails] = useState<DebugDetails>(initialDetails);
	const [loading, setLoading] = useState(true);
	const dimensions = useGenericContentStore((state: any) => state.dimensions);

	useEffect(() => {
		const fetchIpAddress = async (): Promise<string> => {
			try {
				const response = await fetch("https://api.ipify.org?format=json");
				const data = await response.json();
				return data.ip || "";
			} catch (error) {
				AppLogger.log(error);
				return "";
			}
		};
		const fetchDetails = async () => {
			try {
				const regionOverride = await AsyncStorage.getItem(AsyncStorageKeys.RegionOverride);
				const languageOverride = await AsyncStorage.getItem(AsyncStorageKeys.LanguageOverride);
				const isInCarScreen = await AsyncStorage.getItem(AsyncStorageKeys.isInCarScreen);
				const hardwareID = uuidv4();
				const ipAddress = await fetchIpAddress();
				const deviceModelName = isInCarScreen == 'true' ? "mercedez-benz" : "Web";
				setDetails({
					Territory: regionOverride ?? "",
					LanguageSelected: languageOverride ?? "",
					HardwareID: hardwareID,
					IPAddress: ipAddress,
					DeviceModelName: deviceModelName,
					BrowserNameAndVersion: navigator.userAgent,
					AppVersion: VERSION,
					ScreenSize: `${dimensions.screen.width}x${dimensions.screen.height}`
				});
				setLoading(false);
			} catch (error) {
				AppLogger.log(error);
			}
		};
		fetchDetails();
	}, []);

	return (
        // @ts-ignore
        <>{!loading ? <CustomizeDialogBox
            Header="RIDEVU Troubleshoot Dialog"
            Body={
				<ScrollView style={{height: "60vh", width: "90vw"}}>
					{/* @ts-ignore */}
					<StyledContainer>
							{/* @ts-ignore */}
							<StyledText>Territory: {details.Territory}</StyledText>
							{/* @ts-ignore */}
							<StyledText>Language selected: {details.LanguageSelected}</StyledText>
							{/* @ts-ignore */}
							<StyledText>App Config: {`customerzone=${localStorage.getItem(AsyncStorageKeys.profileName)}&wheelpos=${localStorage.getItem(AsyncStorageKeys.wheelPosition)}&language=${localStorage.getItem(AsyncStorageKeys.LanguageOverride)}`}</StyledText>
							{/* @ts-ignore */}
							<StyledText>Data Exchange: {localStorage.getItem(AsyncStorageKeys.dataExchange) ?? 'N/A'}</StyledText>
							{/* @ts-ignore */}
							<StyledText>Hardware ID: {localStorage.getItem(AsyncStorageKeys.hwid)}</StyledText>
							{/* @ts-ignore */}
							<StyledText>IP address: {details.IPAddress}</StyledText>
							{/* @ts-ignore */}
							<StyledText>Device model name: {details.DeviceModelName}</StyledText>
							{/* @ts-ignore */}
							<StyledText>Browser name and version: {details.BrowserNameAndVersion}</StyledText>
							{/* @ts-ignore */}
							<StyledText>App version: {details.AppVersion}</StyledText>
							{/* @ts-ignore */}
							<StyledText>Screen Size: {details.ScreenSize}</StyledText>
							
					</StyledContainer>
				</ScrollView>
            }
            Width={"100%"}
            Height={"100%"}
            onDismiss={props.onDismiss}
            CloseButton={true}
            HideButton={true}
            SingleButton={true}
            HasMiddleButton={false}
        ></CustomizeDialogBox> : <></>}</>
    );
};
export default DebugDialog;