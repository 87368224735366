import { View } from "react-native";
import MainLayoutWithSideNav from "../../components/Layout/MainLayoutWithSideNav";
import { routeSearchScreen } from "../../Routes";
import { useNavigation } from "@react-navigation/native";
import { useEffect, useMemo, useState } from "react";
import { getTransactionFilters, globalAny } from "../../utils/Utils";
import { Carousel, Categories, Pages } from "../../Types";
import PlaylistContainer from "../../components/Layout/PlaylistContainer";
import useGenericContentStore from "../../store/genericContent.store";
import { AppLogger } from "../../utils/AppLogger";
import { getCategories } from "../../services/categoriesService";
import CustomizeLoader from "../../components/Loaders/CustomizeLoader";
import Banner from "./Banner";

const transactionalFilters = getTransactionFilters();

const defaultItem = [
	{
		id: 1,
		isLandscape: false,
		packshot: [1, 2, 3, 4, 5, 6, 7, 8],
	},
	{
		id: 2,
		isLandscape: false,
		packshot: [1, 2, 3, 4, 5, 6, 7, 8],
	},
];

const MyLibrary = () => {
	const navigation = useNavigation();
	const [categories, setCategories] = useState([]);
	const setNavigateEvent = useGenericContentStore((state: any) => state.setNavigateEvent);
	const [isShowLoader, setIsShowLoader] = useState(true);
	const [isMyLibraryEmpty, setIsMyLibraryEmpty] = useState(false);
	const [isContinueWatching, setIsContinueWatching] = useState(true);
	const [startIndex, setStartIndex] = useState(4);
	const [popular, setPopular] = useState<Carousel[]>([]);

	const onSearch = () => {
		//@ts-ignore
		navigation.navigate(routeSearchScreen);
	};

	const renderMyLibraryPlaylist = useMemo(
		() => (
			//@ts-ignore
			<PlaylistContainer
				categories={categories}
				isSeeMore={true}
				location={Categories.myLibrary}
				setIsShowLoader={setIsShowLoader}
				startIndex={startIndex}
				setStartIndex={setStartIndex}
				transactionalFilters={transactionalFilters}
				setIsMyLibraryEmpty={setIsMyLibraryEmpty}
				setIsContinueWatching={setIsContinueWatching}
			/>
		),
		[categories, startIndex, isShowLoader]
	);

	useEffect(() => {
		const controller = new AbortController();
		(async () => {
			try {
				let categories: any = (await getCategories(Categories.myLibrary, transactionalFilters, controller.signal)) ?? [];
				setCategories(categories);
				setNavigateEvent(Pages.library);
				setPopular([]);
			} catch (err) {
				AppLogger.log(err);
			}
		})();
		return () => {
			setCategories([]);
			setNavigateEvent("");
		};
	}, []);

	useEffect(() => {
		if (isMyLibraryEmpty) setIsShowLoader(false);
	}, [isMyLibraryEmpty]);

	return (
		<MainLayoutWithSideNav
			searchIcon={true}
			screenTitle={globalAny.language.header_my_library}
			navTitle={"MyLibrary"}
			onPressRightIcon={onSearch}
		>
			<CustomizeLoader show={isShowLoader} isPlaylist={true} data={defaultItem} />
			{!isContinueWatching && isMyLibraryEmpty ? (
				<View style={{ paddingTop: 112, paddingLeft: 112 }}>
					<Banner data={popular} />
				</View>
			) : (
				<></>
			)}
			{renderMyLibraryPlaylist}
		</MainLayoutWithSideNav>
	);
};

export default MyLibrary;
