import { Dimensions, SafeAreaView, ScrollView, View } from "react-native";
import { useEffect, useState } from "react";
import { routeHome, routeMyLibrary, routeNewCuration } from "../../Routes";
import { useNavigation } from "@react-navigation/native";
import { getPlaylistSummary, getVamPlaylistSummary } from "../../services/apiMoviesContentService";
import { AppLogger } from "../../utils/AppLogger";
import { getItem, getTransactionFilters } from "../../utils/Utils";
import { Categories, Pages } from "../../Types";
import { getCategories } from "../../services/categoriesService";
import AggregatePageLoader from "../../components/Loaders/AggregatePageLoader";
import TopAppBar from "../../components/TopAppBar";
import backIcon from "../../assets/TopNavBarIcon/icon_nav_back.svg";
import closeIcon from "../../assets/TopNavBarIcon/icon_nav_close.svg";
import CardGridPlaylist from "../../components/CardGridPlaylist";
import styled from "styled-components/native";
import useGenericContentStore from "../../store/genericContent.store";
import GalleryContainer from "../../components/Layout/GalleryContainer";

interface Props {
	route?: any;
}

const StyledContainer = styled.View`
	height: 100%;
`;

const StyledGridPackshots = styled.View`
	margin-bottom: 3%;
	padding-top: 153px;
	padding-bottom: 20px;
`;

const myLibrary = Categories.myLibrary;
const studioTwo = Categories.studioTwo;
const contentPerRow = 24;
const pageSize = 30;

const AggregatePage = (props: Props) => {
	const { id, customListId, location } = props.route.params;
	const aggregateTitle = useGenericContentStore((state: any) => state.aggregateTitle);
	const [title, setTitle] = useState(aggregateTitle);
	const [playList, setPlayList] = useState<any>([]);
	const [vamList, setVamList] = useState<any>([]);
	const [next, setNext] = useState(contentPerRow);
	const [searchLoader, setSearchLoader] = useState(false);
	const [windowWidth, setWindowWidth] = useState<any>();
	const [gridSize, setGridSize] = useState<any>(id ? 6 : 4);
	const [currentPage, setCurrentPage] = useState(1);
	const [categoriesStore, setCategoriesStore] = useState([]);
	const [isReachLimit, setIsReachLimit] = useState(false);
	const setNavigateEvent = useGenericContentStore((state: any) => state.setNavigateEvent);
	const setAggregatePageId = useGenericContentStore((state: any) => state.setAggregatePageId);
	const navigation = useNavigation<any>();

	const fetchData = async (pageSize?: number, nextPage?: number, categories?: any) => {
		return id
			? await getPlaylistSummary(location, getTransactionFilters(), pageSize, nextPage, categories)
			: await getVamPlaylistSummary(studioTwo, getTransactionFilters(), pageSize, nextPage, categories);
	};

	const filterData = (page: any) => {
		const filterCriteria = (item: any) => (id ? item.id === Number(id) : item.customListId === customListId);
		const isLandscape = !id && customListId;

		return page
			.filter((item: any) => filterCriteria(item))
			.map((val: any) => {
				return { ...val, isLandscape };
			});
	};

	const Init = async () => {
		try {
			const categories: any = (await getCategories(id ? location : studioTwo, getTransactionFilters())) ?? [];
			const filterByProductId = categories?.filter((cat: any) => cat.id === Number(id) || cat.customListId === customListId);

			setCategoriesStore(filterByProductId);
			const page = await fetchData(pageSize, currentPage, filterByProductId);
			const [data]: any = id ? getItem(filterData(page)) : filterData(page);
			if (id) {
				setPlayList(data);
				setTitle(aggregateTitle || data.title);
				return;
			}

			setVamList(data);
			setTitle(aggregateTitle || data.title);
		} catch (err) {
			AppLogger.log(err);
		}
	};

	useEffect(() => {
		Init();
		setAggregatePageId(id || customListId, location);
		setNavigateEvent(id ? Pages.homeAggregatePage : Pages.vamAggregatePage);
	}, []);

	/**
	 * useEffect to detect resize on window
	 */
	useEffect(() => {
		const resizeHandler = () => {
			setWindowWidth(Dimensions.get("window").width);
			setGridSize(calculateGridItemsByWindowWidth(Dimensions.get("window").width));
		};
		resizeHandler();

		window.addEventListener("resize", resizeHandler);
		return () => window.removeEventListener("resize", resizeHandler);
	}, [windowWidth]);

	const calculateGridItemsByWindowWidth = (width: any) => {
		if (id) return onPortraitSize(width);
		return onLandscapeSize(width);
	};

	const onPortraitSize = (width: number) => {
		if (width <= 1659) return 4;
		return 6;
	};

	const onLandscapeSize = (width: number) => {
		if (width <= 619) return 3;
		return 4;
	};

	const onBackPress = () => {
		if (id) {
			navigation.navigate(location === myLibrary ? routeMyLibrary : routeHome);
		} else {
			navigation.navigate(routeNewCuration);
		}
		setNavigateEvent("");
	};

	const onClose = () => {
		setNavigateEvent("");
		navigation.navigate(routeHome);
	};

	const isCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }: any) => {
		const paddingBottom = 100;
		return layoutMeasurement.height + contentOffset.y >= contentSize.height - paddingBottom;
	};

	const onLazyLoading = async () => {
		AppLogger.log(`Next Page Count: ${next} Playlist Count: ${playList?.products?.length}`);

		if (next >= playList?.products?.length || next >= vamList?.products?.length) return;
		setSearchLoader(true);

		const nextPageNumber = currentPage + 1;
		setCurrentPage(nextPageNumber);

		try {
			
			if (isReachLimit) {
				setNext(next + gridSize);
				return;
			}
			
			const page = await fetchData(pageSize, nextPageNumber, categoriesStore);
			const [nextPageResults]: any = id ? getItem(filterData(page)) : filterData(page);
			setIsReachLimit(nextPageResults.products.length === 0);
			if (nextPageResults) {
				id
					? setPlayList({ ...playList, products: [...playList.products, ...nextPageResults.products] })
					: setPlayList({ ...vamList, products: [...vamList.products, ...nextPageResults.products] });
				setSearchLoader(false);
				setNext(next + gridSize);
			}
		} catch (err) {
			AppLogger.log(err);
		} finally {
			setSearchLoader(false);
		}
	};

	return (
		<SafeAreaView>
			{/* @ts-ignore */}
			<StyledContainer>
				<TopAppBar
					leftIcon={closeIcon}
					leftIconWidth={92}
					leftTitleIcon={backIcon}
					screenTitle={title}
					onPressLeftTitleIcon={onBackPress}
					onPressLeftIcon={onClose}
				/>
				<ScrollView>
					<GalleryContainer>
						<ScrollView
							onScroll={({ nativeEvent }) => {
								if (isCloseToBottom(nativeEvent) && !searchLoader && (vamList.products || playList.products)) {
									onLazyLoading();
								}
							}}
							scrollEventThrottle={400}
							style={{ paddingTop: 30, height: "100vh" }}
						>
							{/* @ts-ignore */}
							<StyledGridPackshots>
								{customListId && vamList.length === 0 && <AggregatePageLoader load={contentPerRow} isVam={true} />}
								{customListId && vamList.length !== 0 && (
									<CardGridPlaylist
										data={vamList.products.slice(0, next)}
										parentProductId={vamList.products[0].productId}
										isAggregate={true}
										isClips={true}
										isRedeem={true}
										isStudioAccess={true}
										isBorderClips={true}
									/>
								)}
								{id && playList?.length !== 0 && <CardGridPlaylist data={playList?.products.slice(0, next)} isAggregate={true} />}
								{id && playList?.length === 0 && <AggregatePageLoader load={contentPerRow} isVam={false} />}
								{searchLoader && (
									<View style={{ marginTop: 20 }}>
										<AggregatePageLoader load={contentPerRow} isVam={!id} slice={gridSize} />
									</View>
								)}
							</StyledGridPackshots>
						</ScrollView>
					</GalleryContainer>
				</ScrollView>
			</StyledContainer>
		</SafeAreaView>
	);
};

export default AggregatePage;
