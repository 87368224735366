import { Animated, Easing } from "react-native";
import { useEffect, useRef, useState } from "react";
import styled from "rn-css";

const AnimatedView = (props: any) => {
	const fadeAnim = useRef(new Animated.Value(0)).current; // Initial value for opacity: 0
	const animDuration = 100;

	useEffect(() => {
		if (props.enabled) {
			Animated.timing(
				fadeAnim,
				// @ts-ignore
				{
					toValue: 0,
					easing: Easing.quad,
					duration: animDuration,
					useNativeDriver: true,
				}
			).start();
		} else {
			Animated.timing(
				fadeAnim,
				// @ts-ignore
				{
					toValue: 100,
					easing: Easing.quad,
					duration: animDuration,
					useNativeDriver: true,
				}
			).start();
		}
	}, [fadeAnim, props.enabled]);

	return (
		<Animated.View // Special animatable View
			style={{
				...props.style,
				width: "80px",
				position: "absolute",
				top: "-1%",
				height: "100%",
				borderRadius: "8px",
				left: fadeAnim.interpolate({
					inputRange: [0, 100],
					outputRange: ["-1%", "27%"],
				}), // Bind opacity to animated value
			}}
		>
			{props.children}
		</Animated.View>
	);
};

interface BtnProps {
	btnDisabled?: boolean;
}

const StyledButton = styled.TouchableOpacity<BtnProps>`
	position: relative;
	width: 110px;
	height: 50px;
	border-radius: 8px;
	border: 2px solid #dddddd;
	background: ${(props) => (props.btnDisabled ? "#141414" : "#3561b8")};
	@media (max-height: 610px) {
		width: 70px;
		height: 30;
	}
`;

const StyledInnerButton = styled.TouchableOpacity<BtnProps>`
	height: 102%;
	width: 100%;
	border-radius: 8px;
	background: ${(props) => (props.btnDisabled ? "#DDDDDD" : "#D4D4D4")};
	justify-content: center;
	align-items: center;
	@media (max-height: 610px) {
		height: 100%;
		width: 60%;
	}
`;

const StyledText = styled.Text`
	color: #000;
	font-size: 20px;
	font-weight: 700;
	@media (max-height: 610px) {
		font-size: 14px;
	}
`;

interface BtnToggleProps {
	onPress?: (buttonDisabled: boolean) => void;
	children?: string;
	defaultValue?: boolean;
	onTrigger?: boolean;
	toggleDisabled?: boolean;
}

const ButtonToggle = (props: BtnToggleProps) => {
	const [disabled, setDisabled] = useState(false);

	const onBtnPress = () => {
		setDisabled(!disabled);

		if (props.onPress) {
			props.onPress(disabled);
		}
	};

	useEffect(() => {
		if (props.defaultValue != undefined) {
			setDisabled(!props.defaultValue);
		}
	}, [props.defaultValue]);

	// Remove this once done
	useEffect(() => {
		if (props.onTrigger != undefined) {
			setDisabled(!props.onTrigger);
		}
	}, [props.onTrigger]);

	return (
		// @ts-ignore
		<StyledButton btnDisabled={disabled} onPress={props.toggleDisabled ? () => {} : onBtnPress}>
			<AnimatedView enabled={disabled}>
				{/* @ts-ignore */}
				<StyledInnerButton btnDisabled={disabled} onPress={props.toggleDisabled ? () => {} : onBtnPress}>
					{/* @ts-ignore */}
					<StyledText>{disabled ? "OFF" : "ON"}</StyledText>
				</StyledInnerButton>
			</AnimatedView>
		</StyledButton>
	);
};

export default ButtonToggle;
