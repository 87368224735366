import CardGridPlaylist from "../CardGridPlaylist";

interface Props {
	isVam?: boolean;
	slice?: number;
	load?: number;
}
const AggregatePageLoader = (props: Props) => {
	const load = props.load ?? 24;
	return (
		<CardGridPlaylist
			data={[...Array(load)].slice(0, props.slice ?? load).map((index) => {
				return { parentProductId: index, packshots: null };
			})}
			hideBorder={true}
			isAggregate={true}
			isClips={props.isVam}
			disabled={true}
		/>
	);
};

export default AggregatePageLoader;
