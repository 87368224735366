import { legalBodyFontSize, spacing_xl } from "../../StyleHelpers";
import Text from "../Text/Text";
import Image from "../Image/Image";
import viewLegalDocButton from "../../assets/Icons/read_more.png";
import TouchableOpacity from "../TouchableOpacity/TouchableOpacity";
import styled from "rn-css";
import ButtonToggle from "../ButtonToggle";
import { View } from "react-native";

interface Props {
	title?: string;
	isToggle?: boolean;
	toggleTitle?: any;
	onPress?: () => void;
	onPressToggle?: () => void;
	disabled?: boolean;
}

const StyledContainer = styled.View`
	flex-direction: row;
	align-items: center;
`;

const StyledAcceptDataOne = styled.View`
`;

const StyledLegalsButton = styled.View<any>`
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	flex-basis: 100%;
	flex-grow: 1;
	width: 100%;
	opacity: ${(props) => (props.disabled ? 0.5 : 1)};
	@media (max-height: 610px) {
		margin-top: -10px;
	}
`;

const StyledImage = styled.View`
	width: 76px;
	height: 76px;
	right: 10%;
	@media (min-width: 640px) {
		width: 56px;
		height: 56px;
	}
	@media (min-width: 1536px) {
		width: 76px;
		height: 76px;
	}
`;

const CreateAccountButton = (props: Props) => {
	return (
		// @ts-ignore
		<StyledContainer>
			{props.isToggle && (
				<View style={{ marginRight: spacing_xl, marginTop: -5, opacity: props.disabled ? 0.5 : 1 }}>
					<ButtonToggle defaultValue={props.toggleTitle} onPress={props.onPressToggle} toggleDisabled={props.disabled} />
				</View>
			)}
			<TouchableOpacity onPress={props.onPress} style={props.isToggle ? { width: "90%" } : { width: "100%" }} disabled={props.disabled}>
				{/* @ts-ignore */}
				<StyledLegalsButton disabled={props.disabled}>
					{/* @ts-ignore */}
					<StyledAcceptDataOne>
						<Text fontSize={legalBodyFontSize}>{props.title}</Text>
					</StyledAcceptDataOne>
					{/* @ts-ignore */}
					<StyledImage>
						<Image source={{ uri: viewLegalDocButton }} resizeMode={"contain"} width={"100%"} height={"100%"} />
					</StyledImage>
				</StyledLegalsButton>
			</TouchableOpacity>
		</StyledContainer>
	);
};

export default CreateAccountButton;
