import React from "react";
import { SafeAreaView } from "react-native";
import styled from "styled-components/native";
import { light_black } from "../../StyleHelpers";
import ImgProgressBar from "./ImgProgressBar";
import TopAppbar from "../../components/TopAppBar";

const StyledTextField = styled.View`
	height: 90px;
	border-radius: 16px;
	overflow: hidden;
	margin-bottom: 24px;
	width: 100%;
`;

const StyledWrapper = styled.View`
	z-index: -1;
	margin: 15vh 14.1% 70px 14.1%;
`;

const ButtonContainer = styled.View`
	height: 72px;
	overflow: hidden;
	border-radius: 16px;
	margin-left: 10px;
	margin-right: 10px;
	flex-grow: 1;
	flex-shrink: 1;
	flex-basis: 100%;
`;

const StyledButtonContainer = styled.View`
	flex-direction: row;
	width: 71.8%;
	position: absolute;
	bottom: 15%;
	margin: 0 14.1% 0 14.1%;
`;

const StyledFormContainer = styled.View`
	margin-top: 82px;
	flex-direction: row;
	gap: 10px;
`;

const StyledPrivacyContainer = styled.View`
	margin-top: 80px;
	left: 15%;
	height: 42px;
	width: 13%;
	position: fixed;
	border-radius: 8px;
	overflow: hidden;
`;

const CodeSignInLoader = () => {
	return (
		<SafeAreaView style={{ flex: 1, backgroundColor: light_black }}>
			<TopAppbar />
			{/* @ts-ignore */}
			<StyledWrapper>
				{/* @ts-ignore */}
				<StyledFormContainer>
					{/* @ts-ignore */}
					<StyledTextField>
						<ImgProgressBar />
					</StyledTextField>
				</StyledFormContainer>
			</StyledWrapper>
			{/* @ts-ignore */}
			<StyledButtonContainer>
				{/* @ts-ignore */}
				<ButtonContainer>
					<ImgProgressBar />
				</ButtonContainer>
				{/* @ts-ignore */}
			</StyledButtonContainer>
		</SafeAreaView>
	);
};

export default CodeSignInLoader;
