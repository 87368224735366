import { black, spacing_l } from "../../StyleHelpers";
import styled, { css } from "styled-components/native";
import useGenericContentStore from "../../store/genericContent.store";

export const StyledScreenContainerView = styled.View<any>`
	width: 100%;
	height: auto;
	border-radius: 16px;
	${(props) => css `background-color: ${props?.themeManager.baseBackgroundColor}` };
	margin-bottom: ${spacing_l};
	padding: ${spacing_l} ${spacing_l} 0px ${spacing_l};
`;

// @ts-ignore
const SettingsContainer = ({ children }) => {
    const themeManager = useGenericContentStore((state: any) => state.themeManager);
	
	return (
		// @ts-ignores
		<StyledScreenContainerView themeManager={themeManager}>{children}</StyledScreenContainerView>
	);
};

export default SettingsContainer;
