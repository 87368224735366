import { View } from "react-native";
import styled, { css } from "styled-components/native";
import ImgProgressBar from "./ImgProgressBar";
import { black } from "../../StyleHelpers";

interface Props {
	show?: boolean;
};

const StyledTitleContainer = styled.View`
	display: flex;
	justify-content: center;
	height: 122px;
	width: calc (100vw - 122px);
	padding: 0px 122px 0px 244px;
	background-color: ${black};
`;

const TitleText = styled.View`
	width: 600px;
	height: 64px;
	border-radius: 16px;
	overflow: hidden;
`;

const RatingText = styled.View`
	width: 526px;
	height: 16px;
	border-radius: 8px;
	margin-top: 7px;
	overflow: hidden;
`;

const StyledVideo = styled.View`
	height: calc(100vh - 244px);
`;

const StyleControlsContainer = styled.View`
	display: flex;
	flex-direction: column;
	justify-content: center;
	height: 122px;
	width: calc(100vw);
	padding: 0px 122px 0px 122px;
	background-color: ${black};
`;

const StyledDurationContainer = styled.View`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
`;

const DurationText = styled.View`
	width: 74px;
	height: 16px;
	border-radius: 8px;
	overflow: hidden;
`;

const SeekBar = styled.View`
	height: 24px;
	overflow: hidden;
	border-radius: 16px;
	overflow: hidden;
	margin-top: 16px;
`;

const PlayerLoader = (props: Props) => {
	if (props.show) {
		return (
			<View style={{height: '100%'}}>	
				{/* @ts-ignore */}
				<StyledTitleContainer>
					{/* @ts-ignore */}
					<TitleText>
						<ImgProgressBar />
					</TitleText>
					{/* @ts-ignore */}
					<RatingText>
						<ImgProgressBar />
					</RatingText>
				</StyledTitleContainer>
				{/* @ts-ignore */}
				<StyledVideo>
					<ImgProgressBar />
				</StyledVideo>
				{/* @ts-ignore */}
				<StyleControlsContainer>
					{/* @ts-ignore */}
					<StyledDurationContainer>
						{/* @ts-ignore */}
						<DurationText>
							<ImgProgressBar />
						</DurationText>
						{/* @ts-ignore */}
						<DurationText>
							<ImgProgressBar />
						</DurationText>
					</StyledDurationContainer>
					{/* @ts-ignore */}
					<SeekBar>
						<ImgProgressBar />
					</SeekBar>
				</StyleControlsContainer>
			</View>
		);
	}
	return null;
};

export default PlayerLoader;
