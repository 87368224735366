import { useWindowDimensions, View } from "react-native";
import { styles } from "./styles";
import Spinner from "../Spinner";
import ridevueLogo from "../../assets/Logo/logos.svg";
import Image from "../Image/Image";
import H3 from "../Typography/H3";
import FontWeightType from "../../models/FontWeightType";
import { globalAny } from "../../utils/Utils";
import { highlightColor } from "../../StyleHelpers";

interface Props {
	show: Boolean;
	name?: string;
	isRecoverPassword?: boolean;
}

const SplashWelcome = ({ show, name, isRecoverPassword }: Props) => {
	const windowDimensions = useWindowDimensions();
	if (show) {
		return (
			<View style={styles.container}>
				<View style={styles.main}>
					<Image source={{ uri: ridevueLogo }} windowDimensions={windowDimensions} width="384px" height="223px" style={styles.logo}></Image>
					<View style={styles.textContainer}>
						{isRecoverPassword ? (
							<H3 fontWeight={FontWeightType.Light}>{globalAny.language.welcome_back.replace(",", "")}</H3>
						) : (
							<H3 fontWeight={FontWeightType.Light}>
								{globalAny.language.welcome} <H3 fontColor={highlightColor}>{name}</H3>!
							</H3>
						)}
					</View>
					<View style={styles.spinnerContainer}>
						<Spinner show={true} isStyle={false}></Spinner>
					</View>
				</View>
			</View>
		);
	}
	return show;
};

export default SplashWelcome;
