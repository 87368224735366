import "swiper/swiper.scss"; // core Swiper
import "swiper/modules/navigation/navigation.scss"; // Navigation module
import "swiper/modules/pagination/pagination.scss"; // Pagination module
import "swiper/modules/autoplay/autoplay.scss"; // Autoplay module
import { View } from "react-native";
import styled, { css } from "styled-components/native";
import styledRN from "rn-css/.";
import ImgProgressBar from "./ImgProgressBar";
import { spacing_l, spacing_m, spacing_s, spacing_xs } from "../../StyleHelpers";

interface Props {
	width: any;
}

const StyledContainer = styled.View`
	width: 58%;
	height: 100vh;
	padding: 2px 0 0 122px;
	z-index: -1;
`;

const StyledGenres = styled.View`
	width: 100%;
	height: 46px;
	color: white;
	flex-direction: row;
	margin-bottom: 41px;
`;

const StyledGenre = styled.View`
	width: 130px;
	border-radius: 16px;
	overflow: hidden;
	margin-right: ${spacing_s};
`;
const StyledRating = styled.View`
	height: 24px;
	border-radius: 16px;
	overflow: hidden;
	margin-bottom: ${spacing_m};
`;

const StyledSynopsis = styled.View<Props>`
	height: 16px;
	border-radius: 16px;
	overflow: hidden;
	margin-bottom: ${spacing_xs};
	${(props) =>
		props.width &&
		css`
			width: ${props.width};
		`}
`;

const StyledButton = styled.View`
	height: 72px;
	border-radius: 16px;
	overflow: hidden;
	margin-bottom: ${spacing_m};
`;

const StyledSynopsisContainer = styled.View`
	width: 81%;
`;

const StyledButtonContainer = styled.View`
	width: 81%;
`;

const StyledContainerColumn = styled.View`
	flex-direction: row;
`;

const StyledImage = styled.View`
	padding-top: ${spacing_l};
	align-items: center;
	flex: 1;
`;

const StyledLoading = styledRN.View`
	width: 420px;
	height: 630px;
	border-radius: 16px;
	overflow: hidden;
	z-index: -1;
    margin-right:112px;
	@media (min-width: 640px) {
		width: 300px;
		height: 480px;
		right:20px;
	}
	@media (min-width: 768px) {
		margin-top:70px;
		width: 300px;
		height: 480px;
	}
	@media (min-width: 1536px) {
		margin-left:112px;
		width: 420px;
		height: 630px;
	}
`;

const TitleDetailsLoader = () => {
	return (
		// @ts-ignore
		<StyledContainerColumn>
			{/* @ts-ignore */}
			<StyledContainer>
				<View>
					{/* @ts-ignore  */}
					<StyledGenres>
						{/* @ts-ignore */}
						<StyledGenre>
							<ImgProgressBar />
						</StyledGenre>
						{/* @ts-ignore */}
						<StyledGenre>
							<ImgProgressBar />
						</StyledGenre>
						{/* @ts-ignore */}
						<StyledGenre>
							<ImgProgressBar />
						</StyledGenre>
					</StyledGenres>
					{/* @ts-ignore */}
					<StyledSynopsisContainer>
						{/* @ts-ignore */}
						<StyledRating>
							<ImgProgressBar />
						</StyledRating>
						<View style={{ marginBottom: spacing_m }}>
							{/* @ts-ignore */}
							<StyledSynopsis>
								<ImgProgressBar />
							</StyledSynopsis>
							{/* @ts-ignore */}
							<StyledSynopsis width={"172px"}>
								<ImgProgressBar />
							</StyledSynopsis>
						</View>
						{/* @ts-ignore */}
						<StyledSynopsis>
							<ImgProgressBar />
						</StyledSynopsis>
						{/* @ts-ignore */}
						<StyledSynopsis>
							<ImgProgressBar />
						</StyledSynopsis>
						{/* @ts-ignore */}
						<StyledSynopsis>
							<ImgProgressBar />
						</StyledSynopsis>
						{/* @ts-ignore */}
						<StyledSynopsis width={"80%"}>
							<ImgProgressBar />
						</StyledSynopsis>
					</StyledSynopsisContainer>
				</View>

				{/* @ts-ignore */}
				<StyledButtonContainer>
					<View style={{ marginTop: spacing_m }}>
						{/* @ts-ignore */}
						<StyledButton>
							<ImgProgressBar />
						</StyledButton>
						{/* @ts-ignore */}
						<StyledButton>
							<ImgProgressBar />
						</StyledButton>
						{/* @ts-ignore */}
						<StyledButton>
							<ImgProgressBar />
						</StyledButton>
					</View>
				</StyledButtonContainer>
			</StyledContainer>

			{/* @ts-ignores */}
			<StyledImage>
				{/* @ts-ignores */}
				<StyledLoading>
					<ImgProgressBar />
				</StyledLoading>
			</StyledImage>
		</StyledContainerColumn>
	);
};

export default TitleDetailsLoader;
