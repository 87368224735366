import { ApiHeaders, ApiVoucherHeaders, AsyncStorageKeys } from "../../Types";
import { globalAny } from "../Utils";

export const getHeaders =(addSession = false) => {
	let headers: ApiHeaders = {
		Accept: "application/json",
		ApiKey: globalAny.APIKEY,
		AppLanguage: getSystemLanguage(),
		"Content-Type": "application/json",
		pragma: "public"
	};
	if (addSession) {
		headers.Session = globalAny.SESSIONKEY;
	}
	return headers;
}

export const  getVoucherHeaders = (addSession: string) => {
	let headers: ApiVoucherHeaders = {
		Accept: "application/json",
		ApiKey: globalAny.APIKEY,
		AppLanguage: getSystemLanguage(),
		Session: addSession,
		Token: ""	
	};
	return headers;
}

export const getSystemLanguage = (): string => {
	let value = localStorage.getItem(AsyncStorageKeys.LanguageOverride);
	if (value == null) {
		return window.navigator.language.substring(0, 2).toUpperCase();
	}

	document.documentElement.setAttribute('lang', value);
	return value.toUpperCase();
};

export const getHeadersWithApplicationJSON = () => {
	return { ApiKey: globalAny.APIKEY, Session: globalAny.SESSIONKEY, AppLanguage: getSystemLanguage(), "content-type": "application/json" };
};


