import { TouchableOpacity, View, Linking, Text } from "react-native";
import styled, { css } from "styled-components/native";
import { white_smoke } from "../../StyleHelpers";
import H4 from "../Typography/H4";
import H5 from "../Typography/H5";
import Image from "../Image/Image";
import External from "../../assets/Icons/External.svg";

interface Props {
	header?: string;
	subHeader?: string;
	body?: string;
	footer?: string;
	onClickableBody?: boolean;
	onClickableFooter?: boolean;
	addMarginChild?: boolean;
	onPressSubHeader?: () => void;
	onPressFooter?: () => void;
	onPressBody?: () => void;
	imageSource?: string;
	isIcon?: boolean;
	isFooterIcon?: boolean;
	disableIcon?: boolean;
}

const StyledContainer = styled.View`
	width: 100%;
`;

const StyledChildMargin = styled.View<Props>`
	${(props) =>
		props.addMarginChild &&
		css`
			margin-left: 20px;
		`}
`;

const StyledText = styled.View`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const IconImage = styled.View`
	margin-left: 5px;
	width: 40px;
	height: 40px;
`;

const StyledFooterContainer = styled.View`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const StyledMainContainer = styled.View`
	flex-direction: row;
	align-items: center;
`;

// @ts-ignore
const LabelContainer = (props: Props) => {
	const handleFooterIconPress = () => {
		if (props.footer) {
			Linking.openURL(props.footer);
		}
	};

	return (
		// @ts-ignore
		<StyledContainer>
			{/* @ts-ignore */}
			<StyledText>
				{/* @ts-ignore */}
				<StyledMainContainer>
					<H4>
						{props.header}
						{/* @ts-ignore */}
						{props.subHeader && props.onClickableBody ? (
							// @ts-ignore
							<TouchableOpacity onPress={props.onPressSubHeader}>
								{/* @ts-ignore */}
								<H4 fontColor={white_smoke}>{props.subHeader}</H4>
							</TouchableOpacity>
						) : (
							<H4 fontColor={white_smoke}>{props.subHeader}</H4>
						)}
					</H4>

					{/* @ts-ignore */}
					{props.imageSource && !props.disableIcon && (
						// @ts-ignore
						<IconImage>
							{/* @ts-ignore */}
							<Image
								source={{ uri: props.imageSource }}
								style={props.isIcon ? { width: 40, height: 40 } : { width: 67.4, height: 31.7 }}
							/>
						</IconImage>
					)}
				</StyledMainContainer>
			</StyledText>
			{/* @ts-ignore */}
			<StyledChildMargin addMarginChild={props.addMarginChild}>
				{/* @ts-ignore */}
				{props.body && props.onClickableBody ? (
					// @ts-ignore
					<TouchableOpacity onPress={props.onPressBody}>
						{/* @ts-ignore */}
						<H5 fontColor={white_smoke}>{props.body}</H5>
					</TouchableOpacity>
				) : (
					<H5 fontColor={white_smoke}>{props.body}</H5>
				)}

				{/* @ts-ignore */}
				{props.footer && props.onClickableFooter ? (
					// @ts-ignore
					<StyledFooterContainer>
						<TouchableOpacity onPress={props.onPressFooter}>
							<H5 fontColor={white_smoke}>{props.footer}</H5>
						</TouchableOpacity>
						{/* @ts-ignore */}
						{props.isFooterIcon && (
							<TouchableOpacity onPress={handleFooterIconPress}>
								<Image source={{ uri: External }} width={40} height={40} />
							</TouchableOpacity>
						)}
					</StyledFooterContainer>
				) : (
					<H5 fontColor={white_smoke}>{props.footer}</H5>
				)}
			</StyledChildMargin>
		</StyledContainer>
	);
};

export default LabelContainer;
