import { Text, StyleSheet, View } from "react-native";
import { black, spacing_l, white } from "../../StyleHelpers";
import styled from "styled-components/native";
import GenresComponent from "../../components/GenresComponent";
import { globalAny } from "../../utils/Utils";

const StyledContainer = styled.View`
	padding: 0px 122px 0 122px;
	height: 100%;
	z-index: -1;
`;

const StyledOverflow = styled.View`
	padding-right: 20px;
`;

const StyledText = styled.View`
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	margin-bottom: ${spacing_l};
`;

const StyledCast = styled.Text`
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

const StyledColumn1 = styled.View`
	width: 15%;
`;

const StyledColumn2 = styled.View`
	width: 81%;
`;

const StyledColumnText = styled.Text`
	text-align: left;
	color: ${white};
`;

const Details = (props: any) => {
	const data = [
		globalAny.language.directed_by,
		globalAny.language.starring,
		globalAny.language.audio_languages,
		globalAny.language.available_subtitles,
	];

	return (
		<View style={{ flex: 1, backgroundColor: black }}>
			{/* @ts-ignore */}
			<StyledContainer>
				{/* @ts-ignore */}
				<StyledOverflow>
					<GenresComponent rating={props.rating} genres={props.genres} />
					{/* @ts-ignore */}
					<Text style={[styles.metadata, { fontSize: 18, marginBottom: spacing_l }]}>{props.ratingReason}</Text>
					<Text style={[styles.metadata, { fontSize: 25, marginBottom: spacing_l }]}>{props?.synopsis}</Text>
					{/* @ts-ignore */}
					{data.map((item: any, index: any) => {
						return (
							// @ts-ignore
							<StyledText key={`metadata-content-${item}`}>
								{/* @ts-ignore */}
								<StyledColumn1>
									{/* @ts-ignore */}
									<StyledColumnText>
										<Text style={[styles.metadata, { fontSize: 18, marginBottom: spacing_l }]}>{item}</Text>
									</StyledColumnText>
								</StyledColumn1>
								{/* @ts-ignore */}
								<StyledColumn2>
									{/* @ts-ignore */}
									<StyledColumnText>
										{index === 0 ? (
											<Text key={`metadata-director-1`} style={[styles.metadata, { fontSize: 18, marginBottom: spacing_l }]}>
												{props.director.join(", ")}
											</Text>
										) : (
											""
										)}

										{index === 1 ? (
											// @ts-ignore
											<StyledCast>
												<Text key={`metadata-cast-1}`} style={[styles.metadata, { fontSize: 18 }]}>
													{props.cast.join(", ")}
												</Text>
											</StyledCast>
										) : (
											""
										)}
										{index === 2 ? <Text style={[styles.metadata, { fontSize: 18 }]}>{props.audioLang}</Text> : ""}
										{index === 3 ? <Text style={[styles.metadata, { fontSize: 18 }]}>{props.subtitle}</Text> : ""}
									</StyledColumnText>
								</StyledColumn2>
							</StyledText>
						);
					})}
					<Text style={[styles.metadata, { fontSize: 18, marginBottom: "5%" }]}>{props.copyright}</Text>
				</StyledOverflow>
			</StyledContainer>
		</View>
	);
};

export default Details;

const styles = StyleSheet.create({
	metadata: {
		color: "white",
	},
	button: {
		fontSize: 25,
		color: "white",
	},
});
