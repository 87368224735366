import StyledText from "./StyledText";
import HeaderTextProps from "./HeaderTextProps";

const H4 = (props: HeaderTextProps) => {
	return (
		// @ts-ignores
		<StyledText fontSize={25} {...props}>
			{props.children}
		</StyledText>
	);
};

export default H4;
