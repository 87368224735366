import { View } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { routeHome } from "../../Routes";
import { useEffect, useMemo, useState } from "react";
import { light_black } from "../../StyleHelpers";
import { globalAny, logout } from "../../utils/Utils";
import SideNav from "../SideNavBar";
import TopAppBar from "../TopAppBar";
import closeIcon from "../../assets/TopNavBarIcon/icon_nav_close.svg";
import searchIcon from "../../assets/TopNavBarIcon/icon_nav_search.png";
import CustomizeDialogBox from "../DialogMessageBox/CustomizeDialogBox";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import MqttSenderService from "../../services/mqtt/MqttSenderService";
import useMqttStore from "../../store/useMqtt.store";
import { AsyncStorageKeys, WheelPosition } from "../../Types";

interface Props {
	children?: any;
	screenTitle?: string;
	navTitle?: string;
	searchIcon?: boolean;
	titleLogo?: any;
	showLogout?: boolean;
	isSettings?: boolean;
	onPressRightIcon?: () => void;
}

const MainLayoutWithSideNav = (props: Props) => {
	const navigation = useNavigation();
	const mqttSender: MqttSenderService = useMqttStore((state: any) => state.mqttSender);
	const wheelPosition = localStorage.getItem(AsyncStorageKeys.wheelPosition);

	const [modalLogout, setModalLogout] = useState(false);

	useEffect(() => {
		if (props.showLogout) {
			setModalLogout(true);
		}
	}, [props.showLogout]);

	const closeSetting = () => {
		//@ts-ignore
		navigation.navigate(routeHome, {});
	};

	const dismissModal = () => {
		setModalLogout(false);
	};

	const exitAccount = async () => {
		if (mqttSender) mqttSender?.removeScreen();

		logout();
		setModalLogout(false);
	};

	const renderTopBar = useMemo(
		() => (
			<TopAppBar
				leftIcon={closeIcon}
				addTitleMarginLeft={true}
				screenTitle={props.screenTitle}
				onPressLeftIcon={closeSetting}
				rightIcon={props.searchIcon ? searchIcon : ""}
				onPressRightIcon={props.onPressRightIcon}
				titleLogo={props.titleLogo}
				isNav={true}
				isSettings={props.isSettings}
			></TopAppBar>
		),
		[props.screenTitle, props.searchIcon, props.titleLogo, props.isSettings]
	);

	const renderNav = useMemo(() => <SideNav currentSelected={props.navTitle} />, [props.navTitle]);

	const wheelPositionStyle = () => {
		if (wheelPosition ===  WheelPosition.RIGHT) {
			return { width: "100vw", zIndex: -1, height: "100%", marginLeft: "-115px" };
		}
		return { width: "100vw", zIndex: -1, height: "100%" };
	};

	const renderChildren = useMemo(() => <View style={wheelPositionStyle()}>{props.children}</View>, [props.children]);

	const renderMainLayout = () => {
		return (
			<View style={{ backgroundColor: light_black }}>
				{renderTopBar}
				{renderNav}
				{renderChildren}
			</View>
		);
	};

	return (
		// @ts-ignore
		<>
			{renderMainLayout()}
			{modalLogout ? (
				<CustomizeDialogBox
					Header={globalAny.language.sure_to_logout}
					Body={globalAny.language.logout_reminder}
					ButtonTextLeft={globalAny.language.logout}
					ButtonTextRight={globalAny.language.cancel}
					SingleButton={false}
					CloseButton={false}
					Width={680}
					Height={"auto"}
					TypeButtonColorLeft={ComponentTypeEnum.Primary}
					TypeButtonColorRight={ComponentTypeEnum.Secondary}
					onDismiss={dismissModal}
					onPressLeft={exitAccount}
					onPressRight={dismissModal}
				></CustomizeDialogBox>
			) : (
				<></>
			)}
		</>
	);
};

export default MainLayoutWithSideNav;
