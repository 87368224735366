import styled, { css } from "styled-components/native";
import { globalAny } from "../../utils/Utils";
import Image from "../Image/Image";
import { peacock_blue, spacing_m, white_smoke } from "../../StyleHelpers";
import SeeMore from "../../assets/Icons/See_more.svg";
import TouchableOpacity from "../TouchableOpacity/TouchableOpacity";
import { useState } from "react";

interface StyledProps {
	isResize?: boolean;
	size?: any;
	borderEvent?: boolean;
	isLandscape?: boolean;
	isClip?: boolean;
	onPress?: () => void;
}
interface Props {
	isResize?: boolean;
	size?: any;
	isLandscape?: boolean;
	isClip?: boolean;
	onPress?: () => void;
}

const StyledSeeMore = styled.View<StyledProps>`
	${(props) =>
		props.isResize &&
		css`
			height: ${props.size}%;
		`}
	${(props) =>
		!props.isResize &&
		css`
			height: 100%;
		`}

	${(props) =>
		props.isLandscape &&
		css`
			width: 25vw;
			aspect-ratio: 17 / 9;
		`}

	${(props) =>
		!props.isLandscape &&
		css`
			width: 15vw;
			aspect-ratio: 2.2 / 3;
		`}

	${(props) =>
		props.borderEvent &&
		css`
			border-radius: 16;
			border-style: "solid";
			border-color: ${white_smoke};
			border-width: 2;
			transition: border-color 0.6s ease;
		`}

	background-color: ${peacock_blue};
	border-radius: 16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const StyledSeeMoreText = styled.Text`
	color: white;
	font-size: 25px;
	margin-top: ${spacing_m};
`;

const SeeMoreComponent = (props: Props) => {
	const [borderEvent, setBorderEvent] = useState(false);
	return (
		<TouchableOpacity
			onPress={props.onPress}
			style={{ height: "100%" }}
			onMouseEnter={() => setBorderEvent(true)}
			onMouseLeave={() => setBorderEvent(false)}
		>
			{/* @ts-ignore */}
			<StyledSeeMore
				isResize={props.isResize}
				size={props.size}
				borderEvent={borderEvent}
				isLandscape={props.isLandscape}
				isClip={props.isClip}
			>
				<Image
					source={{
						uri: SeeMore,
					}}
					style={{ width: "3.33vw", height: "3.33vw" }}
				/>

				{/* @ts-ignore */}
				<StyledSeeMoreText>{globalAny.language.see_more}</StyledSeeMoreText>
			</StyledSeeMore>
		</TouchableOpacity>
	);
};

export default SeeMoreComponent;
