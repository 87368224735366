import styled from "styled-components/native";
import { mid_blue, void_color } from "../../StyleHelpers";


const StyledController = styled.View`
	background-color: ${void_color};
	width: 100%;
	height: 120.5px;
	bottom: 0;
	left: 0;
	position: fixed;
	z-index: -1;
	border-top-width: 1px;
	border-style: solid;
	border-color: ${mid_blue};
	justify-content: center;
`;

// @ts-ignore
const ControllerContainer = ({ children }) => {
	return (
		// @ts-ignores
		<StyledController>{children}</StyledController>
	);
};

export default ControllerContainer;
