import { useState, useEffect } from "react";
import useLegalContentStore from "../store/useLegalContent.store";
import { AsyncStorageKeys } from "../Types";

function usePrivacyPolicy() {
	const [privacyPolicyText, setPrivacyPolicyText] = useState("");
	const legalStoreValue = useLegalContentStore((state) => state.data);

	useEffect(() => {
		(async () => {
			const resultLegal = await legalStoreValue.legalData[AsyncStorageKeys.legals];
			if (resultLegal) {
				let [data] = resultLegal?.legalContents?.filter((val: any) => val?.type === 2) ?? [];
				setPrivacyPolicyText(data?.htmlContent);
			}
		})();
	}, [legalStoreValue.legalData, legalStoreValue]);

	return privacyPolicyText;
}

export default usePrivacyPolicy;
