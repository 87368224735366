import styled from "styled-components/native";
import { light_black, spacing_l } from "../../StyleHelpers";

export const StyledAssignScreenView = styled.View`
	background-color: ${light_black};
	height: 100vh;
`;

// @ts-ignore
const AssignScreen = ({ children }) => {
	return (
		// @ts-ignores
		<StyledAssignScreenView>{children}</StyledAssignScreenView>
	);
};

export default AssignScreen;
