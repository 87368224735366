import { FlatList, ScrollView, View } from "react-native";
import styled from "styled-components/native";
import parse from "html-react-parser";
import LabelContainer from "../SettingsComponent/labelContainer";
import Separator from "../Separator";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import { light_black, spacing_s } from "../../StyleHelpers";
import TouchableOpacity from "../TouchableOpacity/TouchableOpacity";
import { useNavigation } from "@react-navigation/native";
import { routeTitleDetails, TitleDetailsScreenNavigationProp } from "../../Routes";
import useGenericContentStore from "../../store/genericContent.store";
import { Pages } from "../../Types";

interface Props {
	text?: any;
	transactionHistory?: any;
}

const StyledContainer = styled.View`
	height: 100vh;
	z-index: -1;
	padding-top: 180px;
`;

const StyledOverflow = styled.View`
	height: 100%;
	overflow: scroll;
	padding-left: 20px;
	padding-right: 20px;
`;

const StyledText = styled.Text`
	font-size: 25px;
	color: white;
	margin-bottom: 100px;
`;

const BlurFooter = styled.View`
	width: 100%;
	height: 80px;
	position: absolute;
	bottom: 0;
	background-image: linear-gradient(to bottom, transparent, black);
`;

const StyledWrapper = styled.View`
	z-index: -1;
	padding: 0 14.1% 0 14.1%;
`;
const tempText ="";

const PrivacyPolicy = (props: Props) => {
	const navigationTitleScreen = useNavigation<TitleDetailsScreenNavigationProp>();
	const setNavigateEvent = useGenericContentStore((state: any) => state.setNavigateEvent);
	const onPress = (parentProductId: number) => {
		setNavigateEvent(Pages.transactions);
		navigationTitleScreen.navigate(routeTitleDetails, { parentProductId: parentProductId });
	}
	const renderItem = ({ item }: any) => (
		<TouchableOpacity style={{ marginBottom: spacing_s }} onPress={() => onPress(item.parentProductId)}>
			<View style={{ marginBottom: 7 }}>
				<LabelContainer addMarginChild={true} header={item.title} body={item.details}></LabelContainer>
			</View>
			<Separator type={ComponentTypeEnum.Secondary}></Separator>
		</TouchableOpacity>
	);

	
	return (
		<View style={{ zIndex: -1, backgroundColor: light_black }}>
			{/* @ts-ignore */}
			<StyledWrapper>
				{/* @ts-ignore */}
				<StyledContainer>
					{/* @ts-ignore */}
					<StyledOverflow>
						{/* @ts-ignore */}
						{props.text ? <StyledText>{parse(
							props.text.replace(/<a[^>]*>(.*?)/gi, '')
								.replace("</a>", "")
						)  || tempText}</StyledText> : <></>}
						{props.transactionHistory ? (
							<ScrollView style={{marginBottom: 30}}>
								<FlatList
									keyExtractor={(item) => item.parentProductId}
									data={props.transactionHistory}
									extraData={props.transactionHistory}
									renderItem={renderItem}
									showsVerticalScrollIndicator={false}
									showsHorizontalScrollIndicator={false}
								/>
							</ScrollView>
						) : <></>}
					</StyledOverflow>
				</StyledContainer>
			</StyledWrapper>
			<BlurFooter></BlurFooter>
		</View>
	);
};

export default PrivacyPolicy;
