import { useEffect, useState } from "react";
import { LANGUAGE_JSON_STORAGE_KEY, addNewJSONStringsAsKeysIfNeeded, getDateTimePlusHour, getDeviceInfo, globalAny, isProduction, logout } from "../../utils/Utils";
import useSessionStore from "../../store/useSession.store";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { AsyncStorageKeys, CarActivity, ProfileName, Themes, Toggles } from "../../Types";
import CustomizeDialogBox from "../DialogMessageBox/CustomizeDialogBox";
import ComponentTypeEnum from "../../models/ComponentTypeEnum";
import Toast from "../Toast";
import { getApiKey } from "../../services/apiKeyService";
import { AppLogger } from "../../utils/AppLogger";
import { EN } from "../../utils/Strings";
import { getLanguage as getLanguageApi } from "../../services/appWordingService";
import useLoginStore from "../../store/useLogin.store";
import useDrivingStore from "../../store/useDriving.store";
import useGenericContentStore from "../../store/genericContent.store";
import useMqttStore from "../../store/useMqtt.store";
import DebugDialog from "../DialogMessageBox/DebugDialog";
import { Dimensions, View } from "react-native";
import LandingLoader from "../../components/Loaders/LandingLoader";
import { renewSession, renewVoucher } from "../../services/loginService";
import { getFeatureFlags } from "../../services/featureFlagService";
import useToggleStore from "../../store/useToggle.store";
let logoutInterval: NodeJS.Timeout;
let loginInterval: any;
//@ts-ignore
const SessionWrapper = ({ children }) => {
	const [showIdleModal, setShowIdleModal] = useState(false);
	const [showLogout, setShowLogout] = useState(false);
	const [logoutCountDown, setLogoutCountDown] = useState(60);
	const [loginCountDown, setLoginCountDown] = useState(5);
	const isLogout = useSessionStore((state: any) => state.isLogout);
	const setIsLogout = useSessionStore((state: any) => state.setIsLogout);
	const isTimerRunning = useSessionStore((state: any) => state.isTimerRunning);
	const setIsTimerRunning = useSessionStore((state: any) => state.setIsTimerRunning);
	const isSigninLoading = useSessionStore((state: any) => state.isSigninLoading);
	const setIsSigninLoading = useSessionStore((state: any) => state.setIsSigninLoading);
	const setCurrentTheme = useGenericContentStore((state: any) => state.setCurrentTheme);
	const setIsEnabledFloatingButton = useGenericContentStore((state: any) => state.setIsEnabledFloatingButton);
	const setIsDebugVisible = useGenericContentStore((state: any) => state.setIsDebugVisible);
	const isDebugVisible = useGenericContentStore((state: any) => state.isDebugVisible);
	const setDimensions = useGenericContentStore((state: any) => state.setDimensions);
	const currentDimensions = useGenericContentStore((state: any) => state.dimensions);
	const setCarActivity = useDrivingStore((state: any) => state.setCarActivity);
	const setIsInitialLoading = useLoginStore((state: any) => state.setIsInitialLoading);
	const isLoggedIn = useLoginStore((state: any) => state.isLoggedIn);
	const setCheckingLoginInfo = useLoginStore((state: any) => state.setCheckingLoginInfo);
	const checkingLoginInfo = useLoginStore((state: any) => state.checkingLoginInfo);
	const isLoginSuccess = useSessionStore((state: any) => state.isLoginSuccess);
	const setDeviceInfo = useMqttStore((state: any) => state.setDeviceInfo);
	const setDeviceName = useMqttStore((state: any) => state.setDeviceName);
	const setIsToggleQR = useToggleStore((state: any) => state.setIsToggleQR);
	const setIsToggleAutoLogin = useToggleStore((state: any) => state.setIsToggleAutoLogin);
	const setIsToggleMQTT = useToggleStore((state: any) => state.setIsToggleMQTT);
	const setIsToggleAdvanceSettings = useToggleStore((state: any) => state.setIsToggleAdvanceSettings);
	let viewport = document.querySelector("meta[name=viewport]");

	const [allowScale, setAllowScale] = useState(true);

	const onDismissSnackBar = () => {
		setShowLogout(false);
		setIsLogout(false);
		logout();
	};

	const idleLogout = () => {
		setIsLogout(true);
		setIsTimerRunning(false);
		setShowIdleModal(false);
		AsyncStorage.setItem(AsyncStorageKeys.idleExpiry, getDateTimePlusHour().toString());
		setLogoutCountDown(60);
	};

	const idleLogin = () => {
		setShowIdleModal(false);
		setLogoutCountDown(60);
		AsyncStorage.setItem(AsyncStorageKeys.idleExpiry, getDateTimePlusHour().toString());
		setIsTimerRunning(true);
	};

	const isSessionExpired = (sessionExpiry: string) => {
		const now = new Date();
		const sessionExpiryDate = new Date(sessionExpiry);
		return now > sessionExpiryDate;
	};

	const modalBody = () => {
		const bodyText = globalAny.language.modal_logout_body.split("\\n");
		return (
			<View>
				{bodyText.map((text: any) => (
					<View key={`logout-modal-body-text-${text}`}>{text.replace("%d", logoutCountDown.toString())}</View>
				))}
			</View>
		);
	};

	const verifyStorageData = async (isLoggedIn: boolean) => {
		const currentEmail = localStorage.getItem(AsyncStorageKeys.email);
		const currentSession = localStorage.getItem(AsyncStorageKeys.session);
		const currentSessionExpiry = localStorage.getItem(AsyncStorageKeys.sessionExpiry) ?? "1990-01-01";
		const consumerId = localStorage.getItem(AsyncStorageKeys.consumerId);
		const currentProfileName = localStorage.getItem(AsyncStorageKeys.profileName);
		const currentDeviceId = localStorage.getItem(AsyncStorageKeys.deviceId);

		AsyncStorage.getItem(AsyncStorageKeys.theme).then((value) => setCurrentTheme(value ?? Themes.DARK));
		AsyncStorage.getItem(AsyncStorageKeys.carActivity).then((value) => setCarActivity(value ?? CarActivity.PARKED));
		AsyncStorage.getItem(AsyncStorageKeys.floatingButton).then((value) => setIsEnabledFloatingButton(value == "true"));

		const ready =
			currentEmail != null &&
			currentSession != null &&
			consumerId != null &&
			currentDeviceId != null &&
			!isSessionExpired(currentSessionExpiry);

		setIsTimerRunning(ready);

		if (ready) {
			const deviceInfo = getDeviceInfo();
			if (currentSession) globalAny.SESSIONKEY = currentSession;
			if (!isLoggedIn) await renewSession();
			await renewVoucher(localStorage.getItem(AsyncStorageKeys.session));
			if (consumerId) globalAny.CONSUMERID = consumerId;
			if (currentProfileName && currentDeviceId) {
				globalAny.profileName = currentProfileName;
				setDeviceName(currentProfileName);
				setDeviceInfo(deviceInfo);
			}
		}
	};

	const loadStrings = async () => {
		let strings = localStorage.getItem(AsyncStorageKeys.languageJson);
		if (strings) {
			const parsedLanguages = JSON.parse(strings);
			globalAny.language = { ...EN.Strings, ...parsedLanguages, pass_rules: EN.Strings["pass_rules"] };
			addNewJSONStringsAsKeysIfNeeded();
		}

		try {
			let languageJSON = await getLanguageApi();
			if (languageJSON) {
				// @ts-ignore
				globalAny.language = { ...EN.Strings, ...languageJSON, pass_rules: EN.Strings["pass_rules"] };

				// add keys if they dont exist on server, but do locally
				addNewJSONStringsAsKeysIfNeeded();
				await AsyncStorage.setItem(LANGUAGE_JSON_STORAGE_KEY, JSON.stringify(languageJSON));
			}
		} catch (err) {
			// failing to get json from api is ok
			AppLogger.log(err);
		}
	};

	const getUnleashFlags = async () => {
		const toggleParams = [Toggles.qrCode, Toggles.autoLogin, Toggles.mqtt, Toggles.advanceSettings];

		const featureFlags: any = await getFeatureFlags(
			isProduction() 
			? toggleParams.filter((toggle: any) => toggle !== Toggles.advanceSettings)
			: toggleParams
		);
		
		if (!featureFlags?.toggles) return;
		
		const toggleQR = featureFlags?.toggles.find((flag: any) => flag.name === Toggles.qrCode);
		const toggleAutoLogin = featureFlags?.toggles.find((flag: any) => flag.name === Toggles.autoLogin);
		const toggleMQTT = featureFlags?.toggles.find((flag: any) => flag.name === Toggles.mqtt);

		if (toggleQR) setIsToggleQR(toggleQR.enabled);
		if (toggleAutoLogin) setIsToggleAutoLogin(toggleAutoLogin.enabled);
		if (toggleMQTT) setIsToggleMQTT(toggleMQTT.enabled);
		if (isProduction()) return;

		const toggleAdvanceSettings = featureFlags?.toggles.find((flag: any) => flag.name === Toggles.advanceSettings);
		if (toggleAdvanceSettings) setIsToggleAdvanceSettings(toggleAdvanceSettings.enabled);
	};

	const setUserLoginSessionInfo = async () => {
		const apiKey = await AsyncStorage.getItem(AsyncStorageKeys.apiKey);
		// 1. get api key and country
		try {
			await getApiKey();
			if (apiKey) globalAny.APIKEY = apiKey;
		} catch (err) {
			// no api-key, fatal error
			AppLogger.log(err);
		}

		// 2. verify storage data such as profileName, deviceId, session, email
		await verifyStorageData(isLoggedIn);
		// 3. load strings from storage
		await loadStrings();

		await getUnleashFlags();
		setIsInitialLoading(true);

		if (!isLoggedIn) setCheckingLoginInfo(false);
	};

	/**
	 * Checks if the User is already logged in
	 */
	useEffect(() => {
		(async () => {
			// @ts-ignore
			const session = await AsyncStorage.getItem(AsyncStorageKeys.session);
			const apiKey = await AsyncStorage.getItem(AsyncStorageKeys.apiKey);

			if (session && apiKey && isLoggedIn) {
				globalAny.SESSIONKEY = session;
				globalAny.APIKEY = apiKey;
				// Disabled for performance testing
				// const { responseCode }: any = accountCredits();
				// if (!isSessionResponseValid(responseCode)) {
				// 	setIsTimerRunning(false);
				// 	setIsLogout(true);
				// }
			}
			await setUserLoginSessionInfo();
		})();
	}, [isLoggedIn]);

	/**
	 * Checks if the User is already logged in
	 */
	useEffect(() => {
		let mounted = true;
		const deviceIdentifier = localStorage.getItem(AsyncStorageKeys.deviceIdentifier);

		if (mounted) {
			if (!deviceIdentifier) localStorage.setItem(AsyncStorageKeys.deviceIdentifier, `Web${new Date().getTime()}`);
		}

		return () => {
			mounted = false;
		};
	}, []);

	useEffect(() => {
		if (isLogout) setShowLogout(true);
	}, [isLogout]);

	useEffect(() => {
		clearInterval(logoutInterval);
		if (isTimerRunning) {
			clearInterval(logoutInterval);
			setLogoutCountDown(60);
			logoutInterval = setInterval(() => {
				if (logoutCountDown !== 0) setLogoutCountDown((prevCountDown) => prevCountDown - 1);
				const idleExpiry = localStorage.getItem(AsyncStorageKeys.idleExpiry);
				const currentTime = new Date().getTime().toString();
				if (!idleExpiry) return;
				if (currentTime >= idleExpiry) {
					// setShowIdleModal(true);
				}
			}, 1000);
		}
		return () => {
			clearInterval(logoutInterval);
		};
	}, [showIdleModal, isTimerRunning]);

	useEffect(() => {
		if (showIdleModal) {
			if (logoutCountDown === 0) {
				idleLogout();
			}
		}
	}, [logoutCountDown]);

	useEffect(() => {
		if (isLoginSuccess) {
			clearInterval(loginInterval);
			loginInterval = setInterval(() => {
				if (loginCountDown !== 0) setLoginCountDown((prevCountDown) => prevCountDown - 1);
			}, 1000);
		}
		return () => {
			clearInterval(loginInterval);
		};
	}, [loginCountDown, isLoginSuccess]);

	useEffect(() => {
		const subscription = Dimensions.addEventListener("change", ({ window, screen }) => {
			setDimensions({ window, screen });
			setAllowScale(true);
		});

		//@ts-ignore
		return () => subscription?.remove();
	}, []);

	useEffect(() => {
		if (currentDimensions.window && allowScale && viewport) {
			if (currentDimensions.window.height < 500 && localStorage.getItem(AsyncStorageKeys.profileName) !== ProfileName.PassengerScreen) {
				viewport?.setAttribute("content", "width=device-width, initial-scale=0.7, user-scalable=no");
				setAllowScale(false);
			} else {
				viewport?.setAttribute("content", "width=device-width, initial-scale=1, user-scalable=no");
			}
		}
	}, [currentDimensions, viewport]);

	useEffect(() => {
		const sessionExpired = localStorage.getItem(AsyncStorageKeys.sessionExpired);

		if (sessionExpired === "true") {
			setShowLogout(true);
		}
	}, [localStorage.getItem(AsyncStorageKeys.sessionExpired)]);

	if (checkingLoginInfo) {
		return <LandingLoader />;
	}

	return (
		<>
			{children}
			{showIdleModal ? (
				<CustomizeDialogBox
					Header={globalAny.language.modal_logout_header}
					Body={modalBody()}
					ButtonTextLeft={globalAny.language.keep_me_logged_in}
					ButtonTextRight={globalAny.language.logout}
					hasSubTextLeft={true}
					SingleButton={false}
					CloseButton={false}
					Width={680}
					Height={"auto"}
					TypeButtonColorLeft={ComponentTypeEnum.Tertiary}
					TypeButtonColorRight={ComponentTypeEnum.Secondary}
					onPressLeft={idleLogin}
					onPressRight={idleLogout}
				/>
			) : (
				<></>
			)}
			{isSigninLoading ? (
				<Toast
					visible={isSigninLoading}
					text={globalAny.language.validating_credentials}
					type={ComponentTypeEnum.Processing}
					onDismissSnackBar={() => setIsSigninLoading(false)}
				/>
			) : (
				<></>
			)}
			{showLogout ? (
				<Toast
					visible={showLogout}
					text={globalAny.language.session_expired}
					type={ComponentTypeEnum.Secondary}
					onDismissSnackBar={onDismissSnackBar}
				/>
			) : (
				<></>
			)}
			{isLoginSuccess ? (
				<Toast
					visible={isLoginSuccess}
					text={`${globalAny.language.login_succes} (${loginCountDown}) `}
					type={ComponentTypeEnum.Primary}
					onDismissSnackBar={() => setLoginCountDown(5)}
					duration={6000}
				/>
			) : (
				<></>
			)}
			{isDebugVisible && <DebugDialog onDismiss={() => setIsDebugVisible(false)} />}
		</>
	);
};
export default SessionWrapper;
