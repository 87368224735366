import { StyleSheet } from "react-native";

export const styles = StyleSheet.create({
	container: {
		width: "100%",
		height: "100%",
		backgroundColor: "#141414",
		alignItems: "center",
		color: "white",
	},
	textContainer: {
		alignItems: "center",
		justifyContent: "space-between",
		display: "flex",
		marginTop: "4%",
	},
	spinnerContainer: {
		marginTop: "4%",
	},
	text: {
		color: "#008bef",
	},
	logo: {
		marginBottom: "4%",
	},
	main: {
		marginTop: "12.15%",
		alignItems: "center",
	},
});
